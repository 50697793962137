import http from "../../http-common-super-admin";

class DbDataService {
    DbList(data) {
      return http.get("/db/list", { params: data });
    }
    DbRestore(data, config) {
        return http.post("db/restore", data,  config);
    }
    RestoreList() {
      return http.get("db/restore/list");
    }
    updateDbMaster(data) {
      return http.post("db/update", data);
    }
}

export default new DbDataService();
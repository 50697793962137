<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float:right;">
                    <md-button class="md-success" :disabled='isDisabled' @click="createUser()">
                        Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Name</b></label>
                            <md-input type="text" v-model="name"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Email</b></label>
                            <md-input type="email" v-model="email"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Password</b></label>
                            <md-input type="password" v-model="password"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Confirm Password</b></label>
                            <md-input type="password" v-model="confirmPassword"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>VAT</b></label>
                            <md-input type="text" v-model="vat"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Company Number</b></label>
                            <md-input type="text" v-model="companyName"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Address</b></label>
                            <md-textarea type="text" v-model="address"></md-textarea>
                        </md-field>
                    </div>
                </div><br>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Periodicity</label>
                            <md-select v-model="periodicity">
                                <md-option value="Monthly">Monthly</md-option>
                                <md-option value="Quarterly">Quarterly</md-option>
                                <!--<md-option value="Half Yearly">Half Yearly</md-option>
                                <md-option value="Yearly">Yearly</md-option>-->
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Invoice Type</label>
                            <md-select v-model="invoiceType">
                                <md-option value="Manually">Manually</md-option>
                                <md-option value="Online">Online</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Amount (Per Device Per Year)</b></label>
                            <md-input type="number" v-model="devicePrice"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Discount Type</label>
                            <md-select v-model="discountType">
                                <md-option value="Fixed">Fixed</md-option>
                                <md-option value="Percentage">Percentage</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Discount Value</b></label>
                            <md-input type="number" v-model="discountValue"></md-input>
                        </md-field>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      periodicity:"Monthly",
      invoiceType:"Manually",
      devicePrice:12,
      discountType: null,
      discountValue: 0,
      address:"",
      vat:"",
      companyName:"",
    };
  },
  methods: {
    createUser() {
        this.isDisabled = true;
        let data = {
            name: this.name,
            email: this.email,
            password: this.password, address: this.address, vat: this.vat, companyName: this.companyName,
            confirmPassword: this.confirmPassword, discountType: this.discountType, discountValue: this.discountValue,
            role:"Reseller", periodicity: this.periodicity, invoiceType: this.invoiceType, devicePrice: this.devicePrice
        }
        SuperUserService.superUserCreate(data)
          .then((response) => {
            this.isDisabled = false;
            this.$router.push("/admin-reseller-list");
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
    }
  },
  beforeMount() {
  },
  /*mounted() {
    $('#dbNameDropdwon').select2({
      placeholder: "Select Database",
      theme: "classic"
    }).on('select2:select', (e) => {
      if (e.params.data.text == "Select DB") {
        this.dbName = "";
      } else {
        this.dbName = e.params.data.text;
      }
    });
    $('#usersDropdwon').select2({
      placeholder: "Select Users",
      theme: "classic"
    }).on('select2:select', (e) => {
        //console.log("e:", e.params.data)
    });
    this.$nextTick(() => {
      const that = this;
        $('#dbNameDropdwon').on("change",function(){
            that.UserList($(this).val());
        });
    })
  }*/
};
</script>

<style>
</style>

<template>
    <md-card>
        <md-card-content>
            <div v-if="DBenableProfileDisappearing == true">
              <div style="float: right">
                  <md-button class="md-success" :disabled="isDisabled" @click="updateProfileAdvanceSettings()">Save</md-button>
              </div>
              <br />
              <h4 class="text-info"><b>Profile Disappearing Settings</b></h4>
              <div class="md-layout">
                  <div class="md-layout-item">
                    <md-switch v-model="enableProfileDisappearing" @change="profileDisappearingSettingChange()">Enable Profile Disappearing</md-switch>
                  </div>
              </div>
              <div class="md-layout">
                  <div class="md-layout-item">
                    <md-radio class="custom-radio" v-model="disappearingType" value="fixTime">Fix Time</md-radio>
                    <md-radio class="custom-radio" v-model="disappearingType" value="dateTime">Calendar</md-radio>
                  </div>
              </div>
              <div class="md-layout">
                  <div class="md-layout-item md-size-100" v-if="disappearingType == 'fixTime'">
                    <md-field>
                      <label>Minutes</label>
                      <md-input type="number" :disabled="!enableProfileDisappearing" v-model="deviceDeleteIntervalField"></md-input>
                    </md-field>
                    <small class="text-danger"><b>Devices added to this profile will be unenrolled (Factory reset for Fully managed devices; Removal of work profile in case of BYOD—bring your own devices) after the set Interval. To disable this functionality, uncheck Enable Profile Disappearing.</b></small>
                  </div>
                  <div class="md-layout-item md-size-50" v-if="disappearingType == 'dateTime'">
                    <md-field>
                        <md-datepicker v-model="deviceDeleteDate" md-immediately>
                          <label>Date</label>
                        </md-datepicker>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50" v-if="disappearingType == 'dateTime'">
                    <div class="form-group">
                      <label><b>Time</b></label>
                      <input type="time" class="form-control" v-model="deviceDeleteTime">
                    </div>
                  </div>
                  <div class="md-layout-item md-size-100" v-if="disappearingType == 'dateTime'">
                    <small class="text-danger"><b>Devices added to this profile will be unenrolled (Factory reset for Fully managed devices; Removal of work profile in case of BYOD—bring your own devices) after the set Date Time. To disable this functionality, uncheck Enable Profile Disappearing.</b></small>
                  </div>
              </div>
            </div>
            <div v-else>
              <p class="text-danger">This features is disabled for this profile.</p>
            </div>
        </md-card-content>
    </md-card>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import Swal from "sweetalert2";

export default {
  name: 'ProfileAdvanceSettings',
  props: {
    deviceDeleteInterval: Number,
    profileId: String,
  },
  data() {
    return {
        deviceDeleteIntervalField:this.deviceDeleteInterval,
        enableProfileDisappearing:false,
        DBenableProfileDisappearing:false,
        deviceDeleteDate:"",
        deviceDeleteTime:"",
        isDisabled: false,
        disappearingType:"fixTime"
    }
  },
  methods: {
    updateProfileAdvanceSettings() {
        if (this.deviceDeleteIntervalField && this.deviceDeleteDate) {
          return this.$toast.error("Please select either deviceDeleteInterval OR deviceDeleteDateTime"); 
        }
        let data = {
            profileId: this.profileId,
            deviceDeleteInterval: this.deviceDeleteIntervalField,
            enableProfileDisappearing: this.enableProfileDisappearing,
            deviceDeleteDateTime: (this.deviceDeleteDate && this.deviceDeleteTime) ? new Date(this.deviceDeleteDate + " " + this.deviceDeleteTime) : null,
            deviceDeleteTime: this.deviceDeleteTime,
            deviceDeleteDate: this.deviceDeleteDate,
        }
        DeviceProceDataService.updateProfileAdvanceSettings(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.getDeviceProfileDetail();
          }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    profileDisappearingSettingChange() {
      if (this.enableProfileDisappearing == true) {
        Swal.fire({
          title: "Are you sure?",
          text: `All devices in this profile will be factory reseted after X interval.`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, Enable It!",
          buttonsStyling: false,
        }).then((result) => {
          if (!result.value) {
             this.enableProfileDisappearing = false; 
          }
        });
      }
    },

    getDeviceProfileDetail() {
      DeviceProceDataService.GetDeviceProfileDetail(
        this.profileId
      )
        .then((response) => {
          this.DBenableProfileDisappearing = response.data.data.enableProfileDisappearing;
          this.enableProfileDisappearing = response.data.data.enableProfileDisappearing;
          this.deviceDeleteIntervalField = response.data.data.deviceDeleteInterval;
          this.deviceDeleteDate = response.data.data.deviceDeleteDate;
          this.deviceDeleteTime = response.data.data.deviceDeleteTime;
          if (response.data.data.deviceDeleteInterval) {
            this.disappearingType = "fixTime"
          } else {
            this.disappearingType = "dateTime"
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
  },
  beforeMount() {
    this.getDeviceProfileDetail();
  },
}
</script>
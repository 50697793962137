import http from "../../http-common-super-admin";

class WebinarService {
    createWebinar(data) {
        return http.post("webinar/create", data,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    webinarList() {
        return http.get("webinar/list");
    }
    deleteWebinar(data) {
        return http.delete("webinar/delete", {params: data});
    }
    webinarRegistrationList(data) {
        return http.get("webinar/registrations/list", {params: data});
    }
    webinarRequestList(data) {
        return http.get("webinar/requests/list", {params: data});
    }

    configureChrome(data) {
        return http.post("webinar/chrome/request", data);
    }
}

export default new WebinarService();
import http from "../http-common";

class SwitchProfileDataService {
  create(data) {
    return http.post("switch-profile/create", data);
  }
  list() {
    return http.get("switch-profile/list");
  }
  delete(data) {
    return http.delete("switch-profile/delete", { params: data });
  }
}

export default new SwitchProfileDataService();
<template>
  <div class="md-layout-item md-size-100">
    <div style="float: left">
        <h4><p class="text-primary"> {{ first_name }} {{ last_name }} </p></h4>
    </div>
    <md-card>
      <md-card-content>
        <div style="float:right;">
            <md-button class="md-success" type="submit" :disabled='isDisabled' @click="UpdateUser()">Save
              <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
            </md-button>
          </div>
          <hr>
        <div class="md-layout" v-if="userType == 'Android'">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Login Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                User Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="user_name"></md-input>
                </md-field>
                <span class="md-helper-text">Use like this in application configuration 
                    <b><span style="color:red;">$username$</span></b></span>&nbsp;
                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                    <div class="bottom">
                        <p>$username$ value will be replaced by username in application manage configuration.</p>
                    </div>
                </div>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Confirm Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="confirm_password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Device
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="user_device" name="user_device">
                      <md-option>Select Device</md-option>
                      <md-option
                        v-for="device in devices"
                        v-bind:key="device._id"
                        :value="device._id"
                      >
                        {{ device.hardwareInfo.serialNumber }}
                      </md-option>
                    </md-select>
                </md-field>
                <md-switch v-model="search_hide_assign_devices" @change="FilterDeviceList()">Hide Assign Devices</md-switch>
                <ul v-if="user_devices.length > 0">
                  <li v-for="device in user_devices" :key="device._id">
                    <b>{{ device.serialNumber }} </b><a @click="DeleteDeviceUser(device._id)"><i class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Basic Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                First Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="first_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Last Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="last_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Email
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="email"></md-input>
                </md-field>
                <span class="md-helper-text">Use like this in MDM configuration <b><span style="color:red;">$emailAddress$</span></b></span>&nbsp;
                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                    <div class="bottom">
                        <p>$emailAddress$ value will be replaced by email in application manage configuration.</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <h4 class="card-title"><b>Wi-Fi Details</b></h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-33">
                  <md-field>
                    <label>SSID Name</label>
                    <md-input type="text" v-model="wifi_ssid"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33"> 
                  <md-field>
                    <select v-model="wifi_security" class="browser-default custom-select" style="padding: 9px;border: none;">
                      <option value="">Select Security</option>
                      <option value="None" >None</option>
                      <option value="WEP-PSK">WEP-PSK</option>
                      <option value="WPA-PSK">WPA-PSK</option>
                    </select>
                  </md-field>
                </div>
              <div class="md-layout-item md-size-33">
                  <md-field>
                    <label>Passphrase</label>
                    <md-input type="text" style="background-color:unset;" v-model="wifi_passphrase" :disabled="['None'].indexOf(wifi_security) !== -1"></md-input>
                  </md-field>
              </div>
            </div>
          </div>
          <hr>
          
          <h4 class="card-title">Custom Properties <br>(<small>Those attribues used as a placeholder in MDM policy configuration text fields. Use like this <b><span style="color:red;">$user.content.custom1$</span></b>&nbsp;
          <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
              <div class="bottom">
                  <p>$user.content.custom1$ value will be replaced by custom content in application manage configuration.</p>
              </div>
          </div>
          </small>)</h4>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Custom 1
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="custom1"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Custom 2
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="custom2"></md-input>
                </md-field>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout" v-if="userType == 'Chromebook'">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Login Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Email<span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="email" disabled=true></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Password<span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Confirm Password<span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="confirm_password"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Basic Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                First Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="first_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Last Name
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="last_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Organization
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="profileId">
                      <md-option>Select Organization</md-option>
                      <md-option
                        v-for="profile in chromeProfiles"
                        v-bind:key="profile._id"
                        :value="profile._id"
                      >
                        {{ profile.profile_name }}
                      </md-option>
                    </md-select>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      password: "",   
      confirm_password: "",
      user_name: null,
      first_name: null,
      last_name: null,
      email: null,
      custom1: null,
      custom2: null,
      isDisabled: false,
      user_device:null,
      user_devices: [],
      search_hide_assign_devices: false,
      devices: [],
      chromeProfiles: [],
      userType: "",
      profileId:null,
      wifi_ssid: "",
      wifi_security: "",
      wifi_passphrase: "",
    };
  },
  methods: {
    GetApplicationUserDetail() {
      ApplicationUserDataService.GetApplicationUserDetail(
        this.$route.query.id
      )
        .then((response) => {
          if (response.data.data) {
            this.user_name = response.data.data.user_detail.user_name;
            this.first_name = response.data.data.user_detail.first_name;
            this.last_name = response.data.data.user_detail.last_name;
            this.email = response.data.data.user_detail.email;
            this.custom1 = response.data.data.user_detail.custom1;
            this.custom2 = response.data.data.user_detail.custom2;
            this.user_device = (response.data.data.device) ? response.data.data.device._id : null;
            this.user_devices = (response.data.data.devices) ? response.data.data.devices : [];
            this.userType = response.data.data.user_detail.userType;
            this.profileId = response.data.data.user_detail.profileId;
            this.wifi_ssid = response.data.data.user_detail.wifi_ssid;
            this.wifi_security = response.data.data.user_detail.wifi_security;
            this.wifi_passphrase = response.data.data.user_detail.wifi_passphrase;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    GetDevicesList() {
      ApplicationUserDataService.GetDevicesList()
        .then((response) => {
          if (response.data.data) {
            this.devices = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    
    getChromeProfiles() {
      DeviceProceDataService.GetDeviceProfileList({ search_profile_type: "Chromebook" })
        .then((response) => {
          if (response.data.data) {
            this.chromeProfiles = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    DeleteDeviceUser(device_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `User will be unlink from this device`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            device_id : device_id
          };
          DeviceProceDataService.DeleteDeviceUser(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetApplicationUserDetail();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });  
    },
    UpdateUser() {
      this.isDisabled = true;
      var data ={
        'id': this.$route.query.id,
        'password'  : this.password,
        'confirm_password' : this.confirm_password,
        'user_name' : this.user_name,
        'first_name' : this.first_name,
        'last_name' : this.last_name,
        'email' : this.email,
        'user_device': this.user_device,
        'custom1' : this.custom1,
        'custom2' : this.custom2,
        'profileId':this.profileId,
        wifi_ssid: this.wifi_ssid,
        wifi_security: this.wifi_security,
        wifi_passphrase: this.wifi_passphrase,
      };
      ApplicationUserDataService.UpdateUser(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.$router.push("/users");
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response) {
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
    FilterDeviceList() {
      let data = {
        profile_id: "",
        serial_number: "",
        user_id: "",
        hide_assign_devices: this.search_hide_assign_devices,
        type: "",
        imei_number: "",
        model: "",
      }
      DeviceProceDataService.GetProfileDeviceList(data)
        .then((response) => {
          if (response.data.data) {
            this.devices = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetApplicationUserDetail();
    this.GetDevicesList();
    this.getChromeProfiles();
  },
};
</script>
<style>
hr {
  border: 0;
  clear:both;
  width: 100%;               
  background-color:#555855;
  height: 1px;
}
input[type="text"]:disabled {
    background-color: #dddddd;
}
</style>
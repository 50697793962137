<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-warning" :disabled="isDisabled" @click="ExportUsers()">Export</md-button>
        <md-button class="md-info" @click="ImportUserModal = true">Import</md-button>
        <md-button class="md-success" @click="$router.push({ path: 'users/create'})">Create User</md-button>
        <md-button v-if="loginUserDetail && loginUserDetail.tokenFileContent" class="md-secondary" :disabled="isDisabled" @click="syncChromeUsers()">
          Sync Chrome Users<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
      </div>
      <md-card>
        <md-card-actions class="text-center">
        </md-card-actions>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="User Name" v-model="search_user_name"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Email" v-model="search_user_email"></md-input>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Serial Number" v-model="search_serial_number"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                  <md-button class="md-primary" :disabled="isDisabled"  @click="GetApplicationUserList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="User Name" md-sort-by="user_name">{{ item.user_name }}</md-table-cell>
              <md-table-cell md-label="First Name" md-sort-by="first_name">{{ item.first_name }}</md-table-cell>
              <md-table-cell md-label="Last Name" md-sort-by="last_name">{{ item.last_name }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="User Type" md-sort-by="userType">
                <p class="badge-blue" v-if="item.userType == 'Android'">Android</p>
                <p class="badge-chrome-book" v-else-if="item.userType == 'Chromebook'">Chromebook</p>
              </md-table-cell>
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: block ruby;">
                  <md-button class="md-just-icon md-info m-0 md-simple" @click="$router.push({ path: '/users/edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                  <md-button class="md-just-icon md-danger m-0 md-simple" @click="DeleteApplicationUser(item._id)"><md-icon>close</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>

      </md-card>
      <!-- Models -->
      <template>
          <modal v-if="ImportUserModal" @close="ImportUserModal = false">
            <template slot="header">
              <h4 class="modal-title">Import User</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="ImportUserModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <ul class="m-b-0 ul-text-format t-center">
                      <li>
                        Download sample template by clicking <b>Download Template Button</b>.
                      </li>
                      <li>
                        Fill details in the downloaded file, <b>User Name</b> is mandatory fields.   
                      </li>
                      <li>
                        Upload your updated file by clicking <b>Choose File button.</b>
                      </li>
                      <li>
                        Click on <b>Import button</b> and wait for some time.
                      </li>
                    </ul>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <br>
                    <input type="file" @change="handleFileUpload( $event )"/>
                  </div>
                </div>
                <br>
                <a class="md-warning" @click="DownloadSampleImportFile()">Download Template</a>    
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="ImportUserModal = false">Close</md-button>
              <md-button class="md-success" :disabled="isDisabled" @click="ImportUser()">Import</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import ChromeDataService from "../../services/ChromeDataService";
import UserDataService from "../../services/UsersDataService";

import Swal from "sweetalert2";
import { Modal } from "@/components";
import { Pagination } from "@/components";

export default {
  components: {
    Modal,
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      loginUserDetail:"",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",

      //Search Paramters
      search_user_name: "",
      search_user_email: "",
      search_serial_number: "",
      
      ImportUserModal: false,
      csvFile: "",
      csvdata: [
          [1,2,3],
          [4,5,6],
          [7,8,9]
      ],
    };
  },
  methods: {

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    GetApplicationUserList() {
      ApplicationUserDataService.GetApplicationUserList(this.search_user_name,this.search_user_email,this.search_serial_number)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    DeleteApplicationUser(id) {
      Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = { "id" : id };
            ApplicationUserDataService.DeleteApplicationUser(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.GetApplicationUserList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
    },
    handleFileUpload(event){
      this.csvFile = event.target.files[0];
    },
    ImportUser() {
      if (this.csvFile.type != "text/csv") {
          this.$toast.error("Please select csv file for import");
          return false;
      }
      this.isDisabled =true;
      let formData = new FormData();
      formData.append("csvFile", this.csvFile);
      ApplicationUserDataService.ImportUser(formData)
        .then((response) => {
            this.isDisabled =false;
            this.$toast.success(response.data.respones_msg);
            this.GetApplicationUserList();
            this.ImportUserModal = false;
        })
        .catch((e) => {
          this.isDisabled =false;
          this.$toast.error(e.response.data.respones_msg);
          this.GetApplicationUserList();
          this.ImportUserModal = false;
        });
    },
    async ExportUsers() {
      if (this.tableData.length > 0) {
        let export_data = [];
        for await (let user of this.tableData) {
          let user_arr = [
            user.user_name,(user.enroll_serial_number ? user.enroll_serial_number : ""), user.email,'', user.first_name, user.last_name
          ]
          export_data.push(user_arr);
        }
        this.DownloadSampleImportFile(export_data);
      } else {
        this.$toast.error("No data to export");
      }
    },
    DownloadSampleImportFile(export_data = "") {
      let csv = 'UserName,SerialNumber,Email,Password,FirstName,LastName\n';
      if (export_data) {
        export_data.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      if (export_data) {
        anchor.download = 'export-user.csv';
      } else {
        anchor.download = 'import-user-sample.csv';
      }
      anchor.click();
    },

    syncChromeUsers() {
      this.isDisabled = true;
      ChromeDataService.syncChromeUsers()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetApplicationUserList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.loginUserDetail = response.data.data;
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetApplicationUserList();
    this.GetAdminDetail();
  },
};
</script>
<style scoped>
.m-0 {
  margin: 0;
}
.t-center{
  text-align: left;
}
input[type=file] {
  width: auto;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}
input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}
</style>

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        
      </div>
      <md-card>
        <md-card-content>
            <h4><b>Email : {{ email }}</b></h4><br>

            <md-switch v-model="email_verify"><b>Email Verify</b></md-switch><br>
            <md-field>
                <label>Allow Devices</label>
                <md-input v-model="total_devices" type="number"></md-input>
            </md-field>
            <md-field class="country-code">
                <select class="country-dropdown" id="resellerDropdown" v-model="resellerExisting">
                <option v-for="reseller in resellerListData" v-bind:key="reseller._id"
                    :value="reseller._id">{{ reseller.name }}</option>
                </select>
            </md-field>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success" :disabled="isDisabled" @click="UpdateUserDetail()">Update</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../../services/SuperAdmin/UsersDataService";
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      user_id: this.$route.query.user_id,
      email: null,
      email_verify: false,
      total_devices: 0,
      resellerListData: [],
      resellerId:"",
      resellerExisting: "",
    };
  },
  methods: {
    DbUserDetail() {
      let data = { 
        user_id: this.user_id,
      }
      UsersDataService.DbUserDetail(data)
        .then((response) => {
            this.email_verify = response.data.data.email_verify;
            this.total_devices = response.data.data.total_devices;
            this.email = response.data.data.email;
            this.resellerExisting = response.data.masterUserDetail.created_by;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    UpdateUserDetail() {
        let data = { 
            user_id: this.user_id,
            email_verify: this.email_verify,
            total_devices: this.total_devices,
            resellerId: this.resellerId
        }
        UsersDataService.UpdateUserDetail(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
            })
            .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            });
    },
    resellerList() {
        let data = {};
        SuperUserService.superUserList(data)
          .then((response) => {
            this.resellerListData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.DbUserDetail();
    this.resellerList();
  },
  mounted() {
     $('#resellerDropdown').select2({
        placeholder: "Select Reseller",
        theme: "classic"
      }).on('select2:select', (e) => {
        this.resellerId = e.params.data.id;
    });
  }
};
</script>
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">Setup Your Device</h3>
          <h5 class="category">
            Complete all below step for setup.
          </h5>
          <button type="button" @click="$router.push({ path: 'dashboard'})" class="md-button md-simple md-just-icon md-round modal-default-button md-theme-default" style="height: 25px;"><div class="md-ripple"><div class="md-button-content"><i class="fa fa-window-close" aria-hidden="true"></i></div></div></button>
        </template>

        <wizard-tab :before-change="() => validateAPN()" >
          <template slot="label">APN Setup</template>
          <div class="alert alert-secondary alert-dismissible fade show" role="alert" v-if="!ios_mdm_pem_file_topic">
            <strong>Info!</strong> To setup your APN, please follow below step.
            <ol>
              <li>Click on the Download CSR below to download {CertificateSigningRequest.csr}
                <md-button class="md-info" @click="DownloadAppleCsr()"><b style="color:black;">Download CSR</b></md-button>
              </li>
              <li>Navigate to <a href="https://identity.apple.com/pushcert" target="_blank"><b>Apple Push Certificate Portal</b></a> and sign in with the Apple ID.</li>
              <li>Click on Create Certificate button and upload the {CertificateSigningRequest.csr} that you downloaded in Step 1.</li>
              <li>Click Download to download the signed CSR from Apple Portal.</li>
              <li>Upload below the file that you downloaded from Apple Portal Step 4.</li>
            </ol>
          </div>
          <div class="alert alert-secondary alert-dismissible fade show" role="alert" v-if="showRenewCertificateSection">
            <strong>Info!</strong> To renew your APN certificate, please follow below step.
            <ol>
              <li>Click on the Download CSR below to download {CertificateSigningRequest.csr}
                <md-button class="md-info" @click="DownloadAppleCsr()"><b style="color:black;">Download CSR</b></md-button>
              </li>
              <li>Navigate to <a href="https://identity.apple.com/pushcert" target="_blank"><b>Apple Push Certificate Portal</b></a> and sign in with the Apple ID.</li>
              <li>Click on Renew Certificate button and upload the {CertificateSigningRequest.csr} that you downloaded in Step 1.</li>
              <li>Click Download to download the signed CSR from Apple Portal.</li>
              <li>Upload below the file that you downloaded from Apple Portal Step 4.</li>
            </ol>
          </div>

          <div v-if="!ios_mdm_pem_file_topic || showRenewCertificateSection">
            <div class="text-center">
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <input type="file" @change="handleFileUpload( $event )"/>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-button class="md-success" :disabled="isDisabled" @click="CreatePushCertificate()">Upload</md-button>
                    </div>
                </div>
            </div>
          </div>
          <div>
            <p class="text-center text-success" v-if="!isDefaultApn"><b>Your APN setup is successfully completed.</b> </p>
            <p class="text-center text-danger" v-if="certificateExpiryDate"><b>Certificate expire on {{ HumanReadableDateFormat(this.certificateExpiryDate) }}</b> </p>
            <div class="text-center" v-if="isDefaultApn">
              <p class="text-center text-info h4"><b>You are using our default APN setup. If you want to link your personal APN, then delete all existing profiles & devices from our default APN, Click below button for delete our default APN.</b></p>
              <md-button class="md-danger" :disabled="isDisabled" @click="deleteAllIosProfiles()">Delete All Profiles & Devices <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
            </div>
          </div>
        </wizard-tab>

        <wizard-tab :before-change="() => validateCreateProfile()">
          <template slot="label">Profile</template>
          
          <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Profile Name" v-model="profile_name"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-100">
                <md-field>
                <md-input type="text" class="search_text_box" v-model="search_application" placeholder="Search Applications" ></md-input>
                <button class="search_btn" :disabled="isDisabled" @click="SearchAppStoreApp()"><i class="fa fa-search"></i></button>
                </md-field>
                <h4 class="text-success" style="margin:0;"><b>Search applications to kiosk it in next step</b></h4>
              </div>
              <div class="md-layout-item md-size-100">
                <div v-if="profile_applications.length > 0">
                  <div class="profile-app-listing box-shadow" v-if="profile_applications">
                      <div class="screencellbook" v-for="app in profile_applications" :key="app.appId">
                        <img :src="app.icon">
                        <a class="badge-icon" href="javascript:void(0);" @click="DeleteFromProfileApplication(app)">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </a>
                        <span class="apptext" :title="app.title">{{ app.title }}</span>
                      </div>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100">
                <div v-if="play_store_applications">
                  <div class="app-listing box-shadow" v-if="play_store_applications">
                      <div class="screencellbook" v-for="app in play_store_applications" :key="app.appId">
                        <img :src="app.icon">
                        <a class="badge-icon" href="javascript:void(0);" @click="AddToProfileApplication(app)">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <span class="apptext" :title="app.title">{{ app.title }}</span>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </wizard-tab>
        
        <wizard-tab :before-change="() => validateKiosk()">
          <template slot="label">Kiosk</template>
          <div class="md-layout-item md-size-100">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-radio class="custom-radio" v-model="kiosk_type" value="None">None</md-radio>
                <md-radio class="custom-radio" v-model="kiosk_type" value="singleApp" key="101">Single App</md-radio>
                <md-radio class="custom-radio" v-model="kiosk_type" value="MULTI APP" key="102">Multi App</md-radio>
              </div>
            </div>
          </div>
          <!-- Single App Kiosk-->
          <div class="md-layout-item md-size-100" v-if="kiosk_type == 'singleApp'">
            <div v-if="profile_applications.length > 0">
              <h4><b>Select one application to lockdown your devices.</b></h4>
              <ul class="ul-class">
                <li class="li-class" v-for="app in profile_applications" :key="app.appId">
                  <input type="radio" class="radio-d-none" name="single_app_kiosk_package" v-model="single_app_kiosk_package" :value="app.appId" :id="app.appId" />
                  <label class="label" :for="app.appId"><img :src="app.icon" /></label>
                  <span>{{ app.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          
          <!-- Multi App Kiosk-->
          <div class="md-layout-item md-size-100" v-if="kiosk_type == 'MULTI APP'">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <p>Enable Kiosk custom launcher with all approved apps. To add or remove apps from the custom launcher please use the Applications menu.</p>
              </div>
              <div class="md-layout-item md-size-100">
                  <div v-if="profile_applications.length > 0">
                    <div class="profile-app-listing box-shadow" v-if="profile_applications">
                        <div class="screencellbook" v-for="app in profile_applications" :key="app.appId">
                          <img :src="app.icon">
                          <a class="badge-icon" href="javascript:void(0);" @click="DeleteProfileApplication(app)">
                            <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                          </a>
                          <span class="apptext" :title="app.title">{{ app.title }}</span>
                        </div>
                    </div>
                  </div>
              </div>
            </div>  
          </div>
        </wizard-tab>

        <wizard-tab :before-change="() => validateCreateProfileQrCode()">
          <template slot="label">Qr Code</template>
          
          <div class="md-layout" v-if="!display_barcode">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Configuration Name" v-model="configuration_name"></md-input>
                </md-field>
              </div>
          </div>
          
          <div class="md-layout" v-if="display_barcode">
            <div class="md-layout-item md-size-18 md-small-size-100 img-center">
                <div class="android-img">
                  <img :src="barcode" />
                </div>
            </div>
          </div>

        </wizard-tab>

      </simple-wizard>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import UsersDataService from "../../services/UsersDataService";
import IosMdmDataService from "../../services/IosMdmDataService";
import ApplicationDataService from "../../services/ApplicationDataService";
import DeviceProceDataService from "../../services/DeviceProceDataService";

export default {
  data() {
    return {
      isDisabled: false,
      wizardModel: {},
      
      // APN Tab
      ios_mdm_pem_file_topic: null,
      ios_mdm_push_cert: null,
      pemFile: "",
      isDefaultApn: false,
      showRenewCertificateSection: false,
      certificateExpiryDate: null,

      // Create Profile Tab
      profile_name:"",
      profile_id: "",
      profile_applications: [],
      play_store_applications: [],
      search_application:"",

      // Kiosk
      kiosk_wizard_sucess: false,
      kiosk_type: "None",
      single_app_kiosk_package: null,

      //Qr Code 
      configuration_name: "",
      barcode: "",
      display_barcode: false,
    };
  },
  components: {
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateAPN() {
      if (this.ios_mdm_pem_file_topic) {
        return true;
      } else {
        this.$toast.error("Please upload apn certificate.");
        return false;
      }
    },

    async validateCreateProfile() {
      let data = {
        profile_name: this.profile_name,
        exit_passcode: 123456,
        profile_type: "Ios",
        profile_applications: this.profile_applications
      };

    await IosMdmDataService.CreateProfile(data)
      .then((response) => {
        this.profile_id = response.data.data._id;
        this.configuration_name = "Qr Code For " + this.profile_name;
        this.$toast.success(response.data.respones_msg);
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
      });

      if (this.profile_id) {
        return true;
      }
    },

    async validateKiosk() {
      let data = {
        profile_id: this.profile_id,
        kiosk_type: this.kiosk_type
      }
      if (this.kiosk_type == "singleApp") {
          if (!this.single_app_kiosk_package) {
            this.$toast.error("Please select one application.");
            return false;
          }
          data.single_app_kiosk_package_name = this.single_app_kiosk_package;
      }
      if (this.kiosk_type == "MULTI APP") {
          data.multi_app_kiosk_google_custom_launcher = true;
      }
      
      await DeviceProceDataService.UpdateProfileKiosk(data)
        .then((response) => {
          this.kiosk_wizard_sucess = true;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });

      if (this.kiosk_wizard_sucess) {
        return true;
      }
    },

    async validateCreateProfileQrCode() {
      if (this.configuration_name) {
        let data = {
          configuration_name: this.configuration_name,
          profile_id: this.profile_id,
        };
  
      await IosMdmDataService.CreateProfileQrCode(data)
        .then((response) => {
          this.display_barcode = true;
          this.barcode = response.data.data;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
      } else {
        this.$toast.error("Configuration name required.");
      }

      if (this.barcode) {
        return true;
      }
    },
    

    SearchAppStoreApp() {
        if (!this.search_application) {
          this.$toast.error("Please enter application name to search");
        }
        this.isDisabled = true;
        ApplicationDataService.SearchAppStoreApp(this.search_application,'us')
        .then((response) => {
          this.play_store_applications = response.data.data;
          this.isDisabled = false;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });    
    },

    AddToProfileApplication(app) {
      let updated_play_store_application = this.play_store_applications.filter(elem => elem.appId != app.appId)
      this.profile_applications.push(app);
      this.play_store_applications = updated_play_store_application;
    },
    DeleteFromProfileApplication(app) {
      if (this.profile_applications.length == 1) {
        this.profile_applications = [];
      } else {
        let updated_play_store_application = this.profile_applications.filter(elem => elem.appId != app.appId)
        this.play_store_applications.push(app);
        this.profile_applications = updated_play_store_application;
      }
    },

     DeleteProfileApplication(app) {
      var data = {
        package_name: app.appId,
        profile_id: this.profile_id,
      };

      DeviceProceDataService.DeleteProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          let updated_play_store_application = this.profile_applications.filter(elem => elem.appId != app.appId)
          this.play_store_applications.push(app);
          this.profile_applications = updated_play_store_application;
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      Swal.fire({
        title: "Good job!",
        text: "Factory reset your device, tap the welcome screen six times in the same spot to launch the QR code scanner, and scan all your devices!",
        type: "success",
        confirmButtonClass: "md-button md-success",
        buttonsStyling: false,
      });
    },
    GetAdminDetail() {
      let data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          this.ios_mdm_pem_file_topic = response.data.data.ios_mdm_pem_file_topic;
          this.ios_mdm_push_cert = response.data.data.ios_mdm_push_cert;
          this.isDefaultApn = response.data.data.ios_is_default_mdm;
          let certificateData = response.data.data.ios_mdm_pem_file_data;
          if (certificateData.hasOwnProperty("validity")) { 
            this.certificateExpiryDate = certificateData.validity.notAfter;
          }
          if (this.isDefaultApn == false) {
            if (certificateData.hasOwnProperty("validity")) { 
              let today = new Date();
              //let oneMonthAgoDate = new Date(today.setMonth(today.getMonth() - 1));
              let diffTime = Math.abs(new Date(this.certificateExpiryDate) - today);
              let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              if (diffDays <= 30) {
                this.showRenewCertificateSection = true;
              }
            } else {
              this.showRenewCertificateSection = false;
            }
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    
    handleFileUpload(event){
      this.pemFile = event.target.files[0];
    },

    CreatePushCertificate() {
        this.isDisabled =true;
        let formData = new FormData();
        formData.append("fileData", this.pemFile);
        IosMdmDataService.CreatePushCertificate(formData)
        .then((response) => {
            this.isDisabled =false;
            this.$toast.success(response.data.respones_msg);
            this.GetAdminDetail();
        })
        .catch((e) => {
          this.isDisabled =false;
          this.$toast.error(e.response.data.respones_msg);
          this.GetAdminDetail();
        });
    },

    DownloadAppleCsr() {
        const anchor = document.createElement('a');
        anchor.href = process.env.VUE_APP_API_BASE_URL + "/mdm/CertificateSigningRequest.csr";
        anchor.target = '_blank';
        anchor.download = 'CertificateSigningRequest.csr';
        anchor.click();
    },

    resizeWindow() {
      if ($(window).width() < 600) {
        $('.mobile-responsive').removeClass('md-size-50').addClass('md-size-100');
      }
    },

    deleteAllIosProfiles() {
      let message = `Following profile is linked with default APN, please delete all linked profile first and try again.`;
      let data = {
        profile_id: "",
        search_profile_name: "",
        search_profile_type: "Ios"
      }
      DeviceProceDataService.GetDeviceProfileList(data)
        .then((response) => {
          if (response.data.data.length > 0) {
              message += "<ul>";
              for (let profile of response.data.data) {
                  message += '<li>'+profile.profile_name+'</li>';
              }
              message += "</ul>";
          }
          Swal.fire({
            title: "Are you sure?",
            html:message,
            type: "warning",
            showCancelButton: true,
            cancelButtonClass: "md-button md-warning",
            confirmButtonClass: "md-button md-danger",
            confirmButtonText: "Delete All",
          }).then((result) => {
            if (result.value) {
                Swal.fire({
                  title: "All data will be deleted",
                  html: "<ul><li>All work profile will be removed from the devices.</li></ul>",
                  type: "warning",
                  showCancelButton: true,
                  cancelButtonClass: "md-button md-warning",
                  confirmButtonClass: "md-button md-danger",
                  confirmButtonText: "Yes Delete All",
                }).then((result) => {
                  if (result.value) {
                      IosMdmDataService.deleteAllIosProfiles()
                      .then((response) => {
                        this.isDisabled = false;
                        this.$toast.success(response.data.respones_msg);
                        this.GetAdminDetail();
                      })
                      .catch((e) => {
                        this.isDisabled = false;
                        this.$toast.error(e.response.data.respones_msg);
                      });
                  }
                });   
            }
          });
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetAdminDetail();
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
};
</script>
<style scoped>
.text-center{
  text-align: center;
}

.exit-btn {
    float: right;
    margin-top: -60px;
    margin-right: 10px;
}

</style>
<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float:right;">
                    <md-button class="md-success" :disabled='isDisabled' @click="updateUser()">
                        Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Name</b></label>
                            <md-input type="text" v-model="name"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Email</b></label>
                            <md-input type="text" v-model="email" disabled></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>VAT</b></label>
                            <md-input type="text" v-model="vat"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Company Number</b></label>
                            <md-input type="text" v-model="companyName"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Address</b></label>
                            <md-textarea type="text" v-model="address"></md-textarea>
                        </md-field>
                    </div>
                </div><br>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Periodicity</label>
                            <md-select v-model="periodicity">
                                <md-option value="Monthly">Monthly</md-option>
                                <md-option value="Quarterly">Quarterly</md-option>
                                <!--<md-option value="Half Yearly">Half Yearly</md-option>
                                <md-option value="Yearly">Yearly</md-option>-->
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Invoice Type</label>
                            <md-select v-model="invoiceType">
                                <md-option value="Manually">Manually</md-option>
                                <md-option value="Online">Online</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Amount (Per Device Per Year)</b></label>
                            <md-input type="number" v-model="devicePrice"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Discount Type</label>
                            <md-select v-model="discountType">
                                <md-option value="Fixed">Fixed</md-option>
                                <md-option value="Percentage">Percentage</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label><b>Discount Value</b></label>
                            <md-input type="number" v-model="discountValue"></md-input>
                        </md-field>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="row">
                    <div class="col-6">
                        <ul class="list-group" v-if="resellerCustomers.length > 0">
                            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="customer in resellerCustomers" :key="customer._id">
                                {{ customer.email }}<!--<span class="badge badge-danger badge-pill"><a href=""><md-icon>close</md-icon></a></span>-->
                            </li>
                        </ul>
                        <span v-else>No customers found.</span>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      name: "",
      email: "",
      periodicity:"Monthly",
      invoiceType:"Manually",
      devicePrice:12,
      discountType: null,
      discountValue: 0,
      resellerCustomers: [],
      address:"",
      vat:"",
      companyName:"",
    };
  },
  methods: {
    updateUser() {
        this.isDisabled = true;
        let data = {
            id: this.$route.query.id, name: this.name, address: this.address, vat: this.vat, companyName: this.companyName,
            discountType: this.discountType, discountValue: this.discountValue,
            periodicity: this.periodicity, invoiceType: this.invoiceType, devicePrice: this.devicePrice
        }
        SuperUserService.superUserUpdate(data)
          .then((response) => {
            this.isDisabled = false;
            this.$router.push("/admin-reseller-list");
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
    },
    getResellerDetail() {
      var data = { id: this.$route.query.id };
      SuperUserService.superUserDetail(data)
        .then((response) => {
            this.resellerCustomers = response.data.data.customers;
            this.name = response.data.data.name; this.email = response.data.data.email;
            this.periodicity = response.data.data.periodicity;this.invoiceType = response.data.data.invoiceType;
            this.devicePrice = response.data.data.devicePrice;this.discountType = response.data.data.discountType;
            this.discountValue = response.data.data.discountValue;
            this.address = response.data.data.address;
            this.vat = response.data.data.vat;
            this.companyName = response.data.data.companyName;
        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
            this.$router.push('super-admin');
          }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getResellerDetail()
  },
};
</script>

<style>
</style>

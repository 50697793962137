<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <div style="float: right">
                <md-button type="submit" class="md-success" @click="$router.push({ path: 'create-profile-switch'})">Create Switch Profile</md-button>
            </div>
            <md-card>
                <md-card-content>
                    <md-table v-model="tableData"
                    class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Title">
                        {{ item.title }} <br> 
                        <p class="badge-blue" v-if="item.type == 'Daily'">{{ item.type }}</p>
                        <p class="badge-red" v-else>{{ item.type }}</p> 
                    </md-table-cell>
                    <md-table-cell md-label="Profile">
                        {{ item.primaryProfileMessage }} <br> {{ item.secondaryProfileMessage }}
                    </md-table-cell>
                    <md-table-cell md-label="Created On" md-sort-by="created_at">
                      {{ HumanReadableDateFormat(item.created_at, true) }}
                      <span v-if="item.timezone"><br><b> Timezone : {{ item.timezone }}</b></span>
                    </md-table-cell>
                    <md-table-cell md-label="Actions">
                        <div style="display: block ruby;">
                        <md-button class="md-just-icon md-danger md-simple" @click="deleteProfileSwitching(item._id)"><md-icon>close</md-icon></md-button>
                        </div>
                    </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>
<script>
import SwitchProfileDataService from "../../services/SwitchProfileDataService";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
    list() {
        SwitchProfileDataService.list()
        .then((response) => {
            this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deleteProfileSwitching(id) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let data = { "id" : id };
            SwitchProfileDataService.delete(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.list();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
      },
  },
  beforeMount() {
    this.list();
  },
}
</script>
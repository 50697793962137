<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Devices">
                <small><b>Requested Qty: </b>{{ item.subscriptionResponseQty }}</small><br>
                <small><b>Previous Devices: </b>{{ item.old_device }}</small><br>
                <small><b>Current Devices: </b>{{ item.new_device }}</small><br>
                <small v-if="item.expiryAt"><b>Expiry On: </b>{{ HumanReadableDateFormat(item.expiryAt, true) }}</small><br>
              </md-table-cell>
              <md-table-cell md-label="Amount">
                <small><b>GBP {{ item.totalAmount }}</b></small>
              </md-table-cell>
              <md-table-cell md-label="Status">
                <p class="badge-blue" v-if="item.status == 'Success'">Success</p>
                <p class="badge-red" v-else>Fail</p>
                <small v-if="item.subscriptionReason"><b>{{ item.subscriptionReason }}</b></small>
              </md-table-cell>
              <md-table-cell md-label="Message">{{ item.message }}</md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
            </md-table-row>
          </md-table>
          <h4 v-if="tableData.length == 0">No data found.</h4>
        </md-card-content>
        
      </md-card>
    </div>
  </div>
</template>
<script>
import PaymentDataService from "../../services/PaymentDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
    GetPaymentHistory() {
      PaymentDataService.GetPaymentHistory()
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
  },
  beforeMount() {
    this.GetPaymentHistory();
  }
};
</script>
<style>
</style>

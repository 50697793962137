<template>
    <div class="md-layout">
        <div class="md-layout-item md-small-size-100">
            <div class="alert alert-secondary alert-dismissible fade show" role="alert" >
                To enroll chrome device, please configure <b>Google Admin Console & Google Cloud Console</b> and get your client id and client secret. please refer this document for the same
            </div>
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                    <div class="card-icon">
                        <md-icon>settings</md-icon>
                    </div>
                    <h4 class="title">Sync Users and Groups from your Google workspace Directory</h4>
                </md-card-header>

                <md-card-content>
                    <md-field>
                        <label>Domain</label>
                        <md-input v-model="domain" type="text"></md-input>
                    </md-field>
                    <md-field>
                        <label>Domain Admin Account</label>
                        <md-input v-model="domainAdminAccount" type="text"></md-input>
                    </md-field>
                    <md-field >
                        <label>Client Id</label>
                        <md-input v-model="clientID" type="text"></md-input>
                    </md-field>
                    <md-field >
                        <label>Client Secret</label>
                        <md-input v-model="clientSecret"  type="text"></md-input>
                    </md-field>
                </md-card-content>

                <md-card-actions md-alignment="left" >
                    <md-button class="md-success" :disabled="isDisabled" @click="generateAuthUrl()">Integrate</md-button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>
<script>
import ChromeDataService from "../../services/ChromeDataService";
import UserDataService from "../../services/UsersDataService";
export default {
  data() {
    return {
        isDisabled: false,
        success: false,
        domain: "",
        domainAdminAccount: "",
        clientID:"",
        clientSecret:"",
    }
  },
  methods: {
    generateAuthUrl() {
        this.isDisabled =true;
        let data = {
            domain: this.domain,
            domainAdminAccount: this.domainAdminAccount,
            clientID: this.clientID,
            clientSecret: this.clientSecret,
        }
        ChromeDataService.generateAuthUrl(data)
        .then((response) => {
            this.isDisabled =false;
            this.$toast.success(response.data.respones_msg);
            window.location.href = response.data.data;
        })
        .catch((e) => {
            this.isDisabled =false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    manageAuthCallback() {
        let data = {
            code: this.$route.query.code,
        }
        ChromeDataService.manageAuthCallback(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    getAdminDetail() {
      UserDataService.getUserDetail({})
        .then((response) => {
          this.domain = response.data.data.chromeDomain;
          this.domainAdminAccount = response.data.data.domainAdmin;
          this.clientID = response.data.data.clientId;
          this.clientSecret = response.data.data.clientSecret;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              this.$router.push('login');
            }
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
  },
  beforeMount(){
    if (this.$route.query.code) {
      this.manageAuthCallback();
    }
    this.getAdminDetail()
  },
}
</script>
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
        <p v-if="tableData.length == 0">No data found.</p>
          <md-table v-if="tableData.length > 0" v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Customer"> 
                <a href="javascript:void(0)" @click="$router.push({ path: 'admin-invoice-customers', query: { id: item.resellerInvoiceId }})">{{ item.customerEmail }}</a>
              </md-table-cell>
              <md-table-cell md-label="Device Created"> 
                {{ HumanReadableDateFormat(item.deviceAddedDate, true) }}
                <span v-if="item.deviceDeletedDate"> To {{ HumanReadableDateFormat(item.deviceDeletedDate, true) }}</span>
              </md-table-cell>
              <md-table-cell md-label="Total Devices"> 
                {{ item.totalDevices }}
                <br><span class="deleted" v-if="item.deviceDeletedDate">Deleted</span>
              </md-table-cell>
              <md-table-cell md-label="Days">
                <span v-if="!item.deviceDeletedDate"> Used Days : {{ item.notUsedDays }} <br></span>
                <b>Billing Days : {{ item.billingDays }} </b><br>
                <b>Per Day Cost : {{ item.perDayCost }} </b><br>
              </md-table-cell>
              <md-table-cell md-label="Amount">
                <b>Billing Single Devices : {{ item.billingForCurrentYearPerDevice }} </b><br>
                <b>Billing All Devices : {{ item.billingForTotalDevices }} </b><br>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
      invoiceCustomerDevicesList() {
        let data = { id: this.$route.query.id, customerEmail: this.$route.query.email };
        SuperUserService.invoiceCustomerDevicesList(data)
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.invoiceCustomerDevicesList();
  },
};
</script>

<style>
</style>

import http from "../http-common";

class ChromeDataService {
    // Auth Routes
    generateAuthUrl(data) {
        return http.post("chrome/generateAuthUrl", data);
    }
    manageAuthCallback(data) {
        return http.get("chrome/authCallback", {params: data});
    }

    // Profile Routes
    createProfile(data) {
        return http.post("chrome/profile/create", data);
    }
    deleteChromeProfile(data) {
        return http.delete("chrome/profile/delete", {params: data});
    }
    updateProfileApplication(data) {
        return http.post("chrome/profile/application/status/update", data);
    }
    applyPolicy(data) {
        return http.post("chrome/profile/policy/apply", data);
    }
    syncChromeOrg() {
        return http.get("chrome/profile/sync");
    }

    // Device Routes
    syncChromeDevices() {
        return http.get("chrome/devices/sync");
    }
    issueCommand(data) {
        return http.post("chrome/device/issue/command", data);
    }
    deviceAction(data) {
        return http.post("chrome/device/action", data);
    }

    // User Routes
    syncChromeUsers() {
        return http.get("chrome/users/sync");
    }

    getOrgUnitList() {
        return http.get("chrome/org/list");
    }
    moveDeviceToOrg(data) {
        return http.post("chrome/move/device", data);
    }
}
export default new ChromeDataService();
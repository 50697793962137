<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
        <p v-if="tableData.length == 0">No data found.</p>
          <md-table v-if="tableData.length > 0" v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="Email">{{ item.customerEmail }}</md-table-cell>
              <md-table-cell md-label="Amount"> 
                <b>Amount : {{ totalInvoiceAmount(item.invoiceAmount, item.deletedDeviceAmount) }}</b>
                <small v-if="item.discountAmount > 0"><br> <b>Discount : {{ item.discountAmount }}</b></small>
                <br> <small><strong>Purchased Licenses: {{ item.purchasedLicense }} </strong></small>
                <br> <small class="text-danger"><strong>Deleted Devices: {{ item.totalDeletedDevices }} </strong></small>
              </md-table-cell>
              <md-table-cell md-label="Duration">
                {{ item.invoiceDuration }}
                <!--<a href="javascript:void(0)" @click="$router.push({ path: 'admin-invoice-customer-devices', query: { email: item.customerEmail, id: item.resellerInvoiceId }})">{{ item.invoiceDuration }}</a>-->
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
              <!--<md-table-cell md-label="Action">
                <div style="display: block ruby;">
                  <md-button class="md-just-icon md-primary m-0 md-simple" @click="$router.push({ path: 'admin-invoice-customer-devices', query: { email: item.customerEmail, id: item.resellerInvoiceId }})"><md-icon>visibility</md-icon></md-button>
                </div>
              </md-table-cell>-->
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
      invoiceCustomerList() {
        let data = { id: this.$route.query.id };
        SuperUserService.invoiceCustomerList(data)
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      totalDevices(activeDevices, deletedDevices) {
        return activeDevices + deletedDevices;
      },
      totalInvoiceAmount(invoiceAmount, deletedDeviceAmount) {
        return invoiceAmount + deletedDeviceAmount;
      }
  },
  beforeMount() {
    this.invoiceCustomerList();
  },
};
</script>

<style>
</style>

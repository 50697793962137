<template>
  <div>
    <div style="float: right">
        <md-button class="md-success" @click="OpenCreateQRCodeModal()">Create QR Code</md-button>
    </div>
    <md-card v-if="showQrCodeForm">
        <md-card-content>
            <div class="md-layout-item md-size-100" :style="{ padding: 'unset !important' }">
                <span style="float: left;font-weight: 500;"><b>Basic Settings</b></span>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                        <label>Enter Configuration Name <span style="color:red;">*</span></label>
                        <md-input v-model="configuration_name" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                    <md-switch v-model="enable_system_apps">Enable System Apps</md-switch>
                    </div>
                </div>
                <br>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <select id="lang_dropdown" v-model="default_language">
                            <option v-for="lang in languages" v-bind:key="lang.code" :value="lang.code">
                            {{ lang.code + ' - ' + lang.name }}
                            </option>
                        </select>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <select id="timezone_dropdown" v-model="default_timezone">
                            <option v-for="zone in timezone" v-bind:key="zone.value" :value="zone.value">
                            {{ zone.label + ' - ' + zone.value }}
                            </option>
                        </select>
                    </div>
                </div>
                <hr>
                <span style="float: left;font-weight: 500;"><b>WiFi Settings</b></span>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                    <md-field>
                        <label>SSID Name</label>
                        <md-input type="text" v-model="wifi_ssid"></md-input>
                    </md-field>
                    </div>
                    <div class="md-layout-item md-size-33"> 
                        <md-field>
                            <select id="wifi_security" v-model="wifi_security" >
                            <option value="None" selected>None</option>
                            <option value="WPA">WPA</option>
                            <option value="WEP-PSK">WEP-PSK</option>
                            <option value="WPA-PSK">WPA-PSK</option>
                            </select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-field>
                            <label>Passphrase</label>
                            <md-input type="text" v-model="wifi_passphrase"></md-input>
                        </md-field>
                    </div>
                </div>
            </div>
            <div style="float: right">
                <md-button class="md-danger md-simple" @click="showQrCodeForm = false">Close</md-button>
                <md-button class="md-success" :disabled="isDisabled" @click="CreateQrCode()">
                Submit<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
            </div>
        </md-card-content>
    </md-card>
    <md-card>
        <md-card-content>
            <md-table v-model="qrCodeData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Name">{{ item.configuration_name }}
                </md-table-cell>
                <md-table-cell md-label="Default Lang">{{ item.default_language }}</md-table-cell>
                <md-table-cell md-label="Default Timezone">{{ item.default_timezone }}</md-table-cell>
                <md-table-cell md-label="ssid">{{ item.wifi_ssid }}</md-table-cell>
                <md-table-cell md-label="WiFi Type">{{ item.wifi_security }}</md-table-cell>
                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                <md-table-cell md-label="Actions">
                <div style="display: flex;">
                    <md-button class="md-just-icon md-success md-simple">
                    <a  @click="OpenShowQrCodeModal(item.qr_code_url, item.enrollment_token_response,item.profile_id.profile_type, item.byod_qr_code_url, item.current_qr_code, item._id)" href="javascript:void(0)"><md-icon>qr_code</md-icon></a>
                    </md-button>
                </div>
                </md-table-cell>
            </md-table-row>
            </md-table>
        </md-card-content>
    </md-card>
     <!-- QR Code Model -->
      <template>
          <modal v-if="ShowQrCodeModal" @close="ShowQrCodeModal = false">
            <template slot="header">
              <h4 class="modal-title">Qr Code</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="ShowQrCodeModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                
                <div class="md-layout-item">
                    <md-switch v-model="show_byod_qr_code" @change="toggleQrCode()">Show BYOD QR Code</md-switch>
                    <div class="md-layout-item md-size-100" v-if="enterprise_token && show_byod_qr_code">
                      <span style="font-size: 11px;font-weight: 500;">Install Using Link : 
                        <p class="text-primary">{{enterprise_token}} <a href="javascript:void(0)" style="color:green;" @click="CopyProfileContent(enterprise_token )"><i class="fa fa-clone" aria-hidden="true"></i></a></p>
                      </span>
                    </div>
                </div>
                <!--<div class="md-layout-item md-size-100" v-if="enterprise_token">
                  <span style="font-size: 11px;font-weight: 500;">Install Using Link : 
                    <p class="text-primary">{{enterprise_token}} <a href="javascript:void(0)" style="color:green;" @click="CopyProfileContent(enterprise_token )"><i class="fa fa-clone" aria-hidden="true"></i></a></p>
                  </span>
                </div>-->
                <div class="md-layout-item md-size-100">
                  <div class="android-img">
                    <img :src="barcode" style="width:unset;" alt="Qr Code Image" /><br>
                    <p class="text-center"><a href="javascript:void(0)" @click="downloadQrCode()"><i class="fa fa-download" aria-hidden="true"></i></a> </p>
                  </div>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="ShowQrCodeModal = false">Close</md-button>
            </template>
          </modal>
      </template>
  </div>
</template>
<script>
import QrCodeDataService from "../../services/QrCodeDataService";
import { Modal } from "@/components";

export default {
  name: 'CreateQRCode',
  components: {
    Modal
  },  
  props: {
    profileId: String,
  },
  data() {
    return {
        isDisabled: false,
        showQrCodeForm: false,
        configuration_name: null,
        enable_system_apps:false,
        default_timezone: null,
        default_language: null,
        wifi_ssid: "",
        wifi_security: "None",
        wifi_passphrase: "",
        timezone: [
            {"label":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12"},
            {"label":"(GMT-11:00) Midway Island, Samoa","value":"Pacific/Midway"},
            {"label":"(GMT-10:00) Hawaii","value":"Pacific/Honolulu"},
            {"label":"(GMT-09:00) Alaska","value":"US/Alaska"},
            {"label":"(GMT-08:00) Pacific Time (US & Canada)","value":"America/Los_Angeles"},
            {"label":"(GMT-08:00) Tijuana, Baja California","value":"America/Tijuana"},
            {"label":"(GMT-07:00) Arizona","value":"US/Arizona"},
            {"label":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"America/Chihuahua"},
            {"label":"(GMT-07:00) Mountain Time (US & Canada)","value":"US/Mountain"},
            {"label":"(GMT-06:00) Central America","value":"America/Managua"},
            {"label":"(GMT-06:00) Central Time (US & Canada)","value":"US/Central"},
            {"label":"(GMT-06:00) Guadalajara, Mexico City, Monterrey","value":"America/Mexico_City"},
            {"label":"(GMT-06:00) Saskatchewan","value":"Canada/Saskatchewan"},
            {"label":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"America/Bogota"},
            {"label":"(GMT-05:00) Eastern Time (US & Canada)","value":"US/Eastern"},
            {"label":"(GMT-05:00) Indiana (East)","value":"US/East-Indiana"},
            {"label":"(GMT-04:00) Atlantic Time (Canada)","value":"Canada/Atlantic"},
            {"label":"(GMT-04:00) Caracas, La Paz","value":"America/Caracas"},
            {"label":"(GMT-04:00) Manaus","value":"America/Manaus"},
            {"label":"(GMT-04:00) Santiago","value":"America/Santiago"},
            {"label":"(GMT-03:30) Newfoundland","value":"Canada/Newfoundland"},
            {"label":"(GMT-03:00) Brasilia","value":"America/Sao_Paulo"},
            {"label":"(GMT-03:00) Buenos Aires, Georgetown","value":"America/Argentina/Buenos_Aires"},
            {"label":"(GMT-03:00) Greenland","value":"America/Godthab"},
            {"label":"(GMT-03:00) Montevideo","value":"America/Montevideo"},
            {"label":"(GMT-02:00) Mid-Atlantic","value":"America/Noronha"},
            {"label":"(GMT-01:00) Cape Verde Is.","value":"Atlantic/Cape_Verde"},
            {"label":"(GMT-01:00) Azores","value":"Atlantic/Azores"},
            {"label":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"Africa/Casablanca"},
            {"label":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"Etc/Greenwich"},
            {"label":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"Europe/Amsterdam"},
            {"label":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"Europe/Belgrade"},
            {"label":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"Europe/Brussels"},
            {"label":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"Europe/Sarajevo"},
            {"label":"(GMT+01:00) West Central Africa","value":"Africa/Lagos"},
            {"label":"(GMT+02:00) Amman","value":"Asia/Amman"},
            {"label":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"Europe/Athens"},
            {"label":"(GMT+02:00) Beirut","value":"Asia/Beirut"},
            {"label":"(GMT+02:00) Cairo","value":"Africa/Cairo"},
            {"label":"(GMT+02:00) Harare, Pretoria","value":"Africa/Harare"},
            {"label":"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","value":"Europe/Helsinki"},
            {"label":"(GMT+02:00) Jerusalem","value":"Asia/Jerusalem"},
            {"label":"(GMT+02:00) Minsk","value":"Europe/Minsk"},
            {"label":"(GMT+02:00) Windhoek","value":"Africa/Windhoek"},
            {"label":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"Asia/Kuwait"},
            {"label":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"Europe/Moscow"},
            {"label":"(GMT+03:00) Nairobi","value":"Africa/Nairobi"},
            {"label":"(GMT+03:00) Tbilisi","value":"Asia/Tbilisi"},
            {"label":"(GMT+03:30) Tehran","value":"Asia/Tehran"},
            {"label":"(GMT+04:00) Abu Dhabi, Muscat","value":"Asia/Muscat"},
            {"label":"(GMT+04:00) Baku","value":"Asia/Baku"},
            {"label":"(GMT+04:00) Yerevan","value":"Asia/Yerevan"},
            {"label":"(GMT+04:30) Kabul","value":"Asia/Kabul"},
            {"label":"(GMT+05:00) Yekaterinburg","value":"Asia/Yekaterinburg"},
            {"label":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"Asia/Karachi"},
            {"label":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"Asia/Calcutta"},
            {"label":"(GMT+05:45) Kathmandu","value":"Asia/Katmandu"},
            {"label":"(GMT+06:00) Almaty, Novosibirsk","value":"Asia/Almaty"},
            {"label":"(GMT+06:00) Astana, Dhaka","value":"Asia/Dhaka"},
            {"label":"(GMT+06:30) Yangon (Rangoon)","value":"Asia/Rangoon"},
            {"label":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"Asia/Bangkok"},
            {"label":"(GMT+07:00) Krasnoyarsk","value":"Asia/Krasnoyarsk"},
            {"label":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"Asia/Hong_Kong"},
            {"label":"(GMT+08:00) Kuala Lumpur, Singapore","value":"Asia/Kuala_Lumpur"},
            {"label":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"Asia/Irkutsk"},
            {"label":"(GMT+08:00) Perth","value":"Australia/Perth"},
            {"label":"(GMT+08:00) Taipei","value":"Asia/Taipei"},
            {"label":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"Asia/Tokyo"},
            {"label":"(GMT+09:00) Seoul","value":"Asia/Seoul"},
            {"label":"(GMT+09:00) Yakutsk","value":"Asia/Yakutsk"},
            {"label":"(GMT+09:30) Adelaide","value":"Australia/Adelaide"},
            {"label":"(GMT+09:30) Darwin","value":"Australia/Darwin"},
            {"label":"(GMT+10:00) Brisbane","value":"Australia/Brisbane"},
            {"label":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"Australia/Canberra"},
            {"label":"(GMT+10:00) Hobart","value":"Australia/Hobart"},
            {"label":"(GMT+10:00) Guam, Port Moresby","value":"Pacific/Guam"},
            {"label":"(GMT+10:00) Vladivostok","value":"Asia/Vladivostok"},
            {"label":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"Asia/Magadan"},
            {"label":"(GMT+12:00) Auckland, Wellington","value":"Pacific/Auckland"},
            {"label":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"Pacific/Fiji"},
            {"label":"(GMT+13:00) Nuku'alofa","value":"Pacific/Tongatapu"}
        ],
        languages: [
            { code : 'ab', name : 'Abkhazian' },
            { code : 'aa', name : 'Afar' },
            { code : 'af', name : 'Afrikaans' },
            { code : 'ak', name : 'Akan' },
            { code : 'sq', name : 'Albanian' },
            { code : 'am', name : 'Amharic' },
            { code : 'ar', name : 'Arabic' },
            { code : 'an', name : 'Aragonese' },
            { code : 'hy', name : 'Armenian' },
            { code : 'as', name : 'Assamese' },
            { code : 'av', name : 'Avaric' },
            { code : 'ae', name : 'Avestan' },
            { code : 'ay', name : 'Aymara' },
            { code : 'az', name : 'Azerbaijani' },
            { code : 'bm', name : 'Bambara' },
            { code : 'ba', name : 'Bashkir' },
            { code : 'eu', name : 'Basque' },
            { code : 'be', name : 'Belarusian' },
            { code : 'bn', name : 'Bengali' },
            { code : 'bh', name : 'Bihari languages' },
            { code : 'bi', name : 'Bislama' },
            { code : 'bs', name : 'Bosnian' },
            { code : 'br', name : 'Breton' },
            { code : 'bg', name : 'Bulgarian' },
            { code : 'my', name : 'Burmese' },
            { code : 'ca', name : 'Catalan, Valencian' },
            { code : 'km', name : 'Central Khmer' },
            { code : 'ch', name : 'Chamorro' },
            { code : 'ce', name : 'Chechen' },
            { code : 'ny', name : 'Chichewa, Chewa, Nyanja' },
            { code : 'zh', name : 'Chinese' },
            { code : 'cu', name : 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
            { code : 'cv', name : 'Chuvash' },
            { code : 'kw', name : 'Cornish' },
            { code : 'co', name : 'Corsican' },
            { code : 'cr', name : 'Cree' },
            { code : 'hr', name : 'Croatian' },
            { code : 'cs', name : 'Czech' },
            { code : 'da', name : 'Danish' },
            { code : 'dv', name : 'Divehi, Dhivehi, Maldivian' },
            { code : 'nl', name : 'Dutch, Flemish' },
            { code : 'dz', name : 'Dzongkha' },
            { code : 'en', name : 'English' },
            { code : 'eo', name : 'Esperanto' },
            { code : 'et', name : 'Estonian' },
            { code : 'ee', name : 'Ewe' },
            { code : 'fo', name : 'Faroese' },
            { code : 'fj', name : 'Fijian' },
            { code : 'fi', name : 'Finnish' },
            { code : 'fr', name : 'French' },
            { code : 'ff', name : 'Fulah' },
            { code : 'gd', name : 'Gaelic, Scottish Gaelic' },
            { code : 'gl', name : 'Galician' },
            { code : 'lg', name : 'Ganda' },
            { code : 'ka', name : 'Georgian' },
            { code : 'de', name : 'German' },
            { code : 'ki', name : 'Gikuyu, Kikuyu' },
            { code : 'el', name : 'Greek (Modern)' },
            { code : 'kl', name : 'Greenlandic, Kalaallisut' },
            { code : 'gn', name : 'Guarani' },
            { code : 'gu', name : 'Gujarati' },
            { code : 'ht', name : 'Haitian, Haitian Creole' },
            { code : 'ha', name : 'Hausa' },
            { code : 'he', name : 'Hebrew' },
            { code : 'hz', name : 'Herero' },
            { code : 'hi', name : 'Hindi' },
            { code : 'ho', name : 'Hiri Motu' },
            { code : 'hu', name : 'Hungarian' },
            { code : 'is', name : 'Icelandic' },
            { code : 'io', name : 'Ido' },
            { code : 'ig', name : 'Igbo' },
            { code : 'id', name : 'Indonesian' },
            { code : 'ia', name : 'Interlingua (International Auxiliary Language Association)' },
            { code : 'ie', name : 'Interlingue' },
            { code : 'iu', name : 'Inuktitut' },
            { code : 'ik', name : 'Inupiaq' },
            { code : 'ga', name : 'Irish' },
            { code : 'it', name : 'Italian' },
            { code : 'ja', name : 'Japanese' },
            { code : 'jv', name : 'Javanese' },
            { code : 'kn', name : 'Kannada' },
            { code : 'kr', name : 'Kanuri' },
            { code : 'ks', name : 'Kashmiri' },
            { code : 'kk', name : 'Kazakh' },
            { code : 'rw', name : 'Kinyarwanda' },
            { code : 'kv', name : 'Komi' },
            { code : 'kg', name : 'Kongo' },
            { code : 'ko', name : 'Korean' },
            { code : 'kj', name : 'Kwanyama, Kuanyama' },
            { code : 'ku', name : 'Kurdish' },
            { code : 'ky', name : 'Kyrgyz' },
            { code : 'lo', name : 'Lao' },
            { code : 'la', name : 'Latin' },
            { code : 'lv', name : 'Latvian' },
            { code : 'lb', name : 'Letzeburgesch, Luxembourgish' },
            { code : 'li', name : 'Limburgish, Limburgan, Limburger' },
            { code : 'ln', name : 'Lingala' },
            { code : 'lt', name : 'Lithuanian' },
            { code : 'lu', name : 'Luba-Katanga' },
            { code : 'mk', name : 'Macedonian' },
            { code : 'mg', name : 'Malagasy' },
            { code : 'ms', name : 'Malay' },
            { code : 'ml', name : 'Malayalam' },
            { code : 'mt', name : 'Maltese' },
            { code : 'gv', name : 'Manx' },
            { code : 'mi', name : 'Maori' },
            { code : 'mr', name : 'Marathi' },
            { code : 'mh', name : 'Marshallese' },
            { code : 'ro', name : 'Romanian' },
            { code : 'mn', name : 'Mongolian' },
            { code : 'na', name : 'Nauru' },
            { code : 'nv', name : 'Navajo, Navaho' },
            { code : 'nd', name : 'Northern Ndebele' },
            { code : 'ng', name : 'Ndonga' },
            { code : 'ne', name : 'Nepali' },
            { code : 'se', name : 'Northern Sami' },
            { code : 'no', name : 'Norwegian' },
            { code : 'nb', name : 'Norwegian Bokmål' },
            { code : 'nn', name : 'Norwegian Nynorsk' },
            { code : 'ii', name : 'Nuosu, Sichuan Yi' },
            { code : 'oc', name : 'Occitan (post 1500)' },
            { code : 'oj', name : 'Ojibwa' },
            { code : 'or', name : 'Oriya' },
            { code : 'om', name : 'Oromo' },
            { code : 'os', name : 'Ossetian, Ossetic' },
            { code : 'pi', name : 'Pali' },
            { code : 'pa', name : 'Panjabi, Punjabi' },
            { code : 'ps', name : 'Pashto, Pushto' },
            { code : 'fa', name : 'Persian' },
            { code : 'pl', name : 'Polish' },
            { code : 'pt', name : 'Portuguese' },
            { code : 'qu', name : 'Quechua' },
            { code : 'rm', name : 'Romansh' },
            { code : 'rn', name : 'Rundi' },
            { code : 'ru', name : 'Russian' },
            { code : 'sm', name : 'Samoan' },
            { code : 'sg', name : 'Sango' },
            { code : 'sa', name : 'Sanskrit' },
            { code : 'sc', name : 'Sardinian' },
            { code : 'sr', name : 'Serbian' },
            { code : 'sn', name : 'Shona' },
            { code : 'sd', name : 'Sindhi' },
            { code : 'si', name : 'Sinhala, Sinhalese' },
            { code : 'sk', name : 'Slovak' },
            { code : 'sl', name : 'Slovenian' },
            { code : 'so', name : 'Somali' },
            { code : 'st', name : 'Sotho, Southern' },
            { code : 'nr', name : 'South Ndebele' },
            { code : 'es', name : 'Spanish, Castilian' },
            { code : 'su', name : 'Sundanese' },
            { code : 'sw', name : 'Swahili' },
            { code : 'ss', name : 'Swati' },
            { code : 'sv', name : 'Swedish' },
            { code : 'tl', name : 'Tagalog' },
            { code : 'ty', name : 'Tahitian' },
            { code : 'tg', name : 'Tajik' },
            { code : 'ta', name : 'Tamil' },
            { code : 'tt', name : 'Tatar' },
            { code : 'te', name : 'Telugu' },
            { code : 'th', name : 'Thai' },
            { code : 'bo', name : 'Tibetan' },
            { code : 'ti', name : 'Tigrinya' },
            { code : 'to', name : 'Tonga (Tonga Islands)' },
            { code : 'ts', name : 'Tsonga' },
            { code : 'tn', name : 'Tswana' },
            { code : 'tr', name : 'Turkish' },
            { code : 'tk', name : 'Turkmen' },
            { code : 'tw', name : 'Twi' },
            { code : 'ug', name : 'Uighur, Uyghur' },
            { code : 'uk', name : 'Ukrainian' },
            { code : 'ur', name : 'Urdu' },
            { code : 'uz', name : 'Uzbek' },
            { code : 've', name : 'Venda' },
            { code : 'vi', name : 'Vietnamese' },
            { code : 'vo', name : 'Volap_k' },
            { code : 'wa', name : 'Walloon' },
            { code : 'cy', name : 'Welsh' },
            { code : 'fy', name : 'Western Frisian' },
            { code : 'wo', name : 'Wolof' },
            { code : 'xh', name : 'Xhosa' },
            { code : 'yi', name : 'Yiddish' },
            { code : 'yo', name : 'Yoruba' },
            { code : 'za', name : 'Zhuang, Chuang' },
            { code : 'zu', name : 'Zulu' }
        ],

        //Qr Code
        qrCodeData: [],
        barcode: "",
        byow_qr_code: "",
        normal_qr_code: "",
        show_byod_qr_code: false,
        enterprise_token : "",
        ShowQrCodeModal: false,
        currentProfileType: "",
        currentQrCodeID: "",
    }
  },
  methods: {
    OpenCreateQRCodeModal() {
      this.showQrCodeForm = true;
      setTimeout(function () {
        $('#lang_dropdown').select2({
          placeholder: "Select Language",
          theme: "classic"
        });
        $('#timezone_dropdown').select2({
          placeholder: "Select Timezone",
          theme: "classic"
        });
        $('#wifi_security').select2({
          placeholder: "Select WiFi Security",
          theme: "classic"
        });
      }, 300)
    },
    
    CreateQrCode() {
        this.isDisabled = true;
        let data = {
            configuration_name: this.configuration_name,
            profile_id: this.profileId,
            default_language: $('#lang_dropdown').val(),
            default_timezone: $('#timezone_dropdown').val(),
            enable_system_apps: this.enable_system_apps,
            wifi_ssid: this.wifi_ssid,
            wifi_security: $('#wifi_security').val(),
            wifi_passphrase: this.wifi_passphrase,
        };

        QrCodeDataService.CreateQrCode(data)
            .then((response) => {
                this.isDisabled = false;
                this.classicModal = false;
                this.configuration_name = "";
                this.wifi_ssid = "";
                this.wifi_security = "";
                this.GetQrCodeList();
                this.showQrCodeForm = false;
                this.$toast.success(response.data.respones_msg);
            })
            .catch((e) => {
                this.isDisabled = false;
                $("#profile_dropdwon").val('').trigger('change');
                $("#timezone_dropdown").val('').trigger('change');
                $("#lang_dropdown").val('').trigger('change');
                $("#wifi_security").val('').trigger('change');
                this.$toast.error(e.response.data.respones_msg);
        });  
    },
    GetQrCodeList() {
        let data = {
          profile_id: this.profileId
        }
        QrCodeDataService.GetQrCodeList(data)
          .then((response) => {
            if (response.data.data) {
              this.qrCodeData = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e);
          });
    },

    OpenShowQrCodeModal(qrCode, enterpriseToken, profileType, byow_qr_code, current_qr_code, id) {
        this.currentQrCodeID = id;
        if (current_qr_code == "BYOD") {
          this.show_byod_qr_code = true;
          this.barcode = byow_qr_code;
        } else {
          this.show_byod_qr_code = false;
          this.barcode = qrCode;
        }
        //this.barcode = qrCode
        this.byow_qr_code = byow_qr_code;
        this.normal_qr_code = qrCode;
        this.currentProfileType = profileType;

        if (enterpriseToken) {
          if (profileType == "Android") {
            this.enterprise_token = "https://enterprise.google.com/android/enroll?et="+enterpriseToken.value
          } else {
            this.enterprise_token = enterpriseToken.value
          }
        } else {
          this.enterprise_token = ""
        }
        this.ShowQrCodeModal = true;
      },
      toggleQrCode() {
        let old_qr_code = this.barcode;
        this.barcode = (this.show_byod_qr_code) ? this.byow_qr_code : this.normal_qr_code;
        this.byow_qr_code = old_qr_code;

        let data = { id : this.currentQrCodeID, current_qr_code: (this.show_byod_qr_code == true) ? "BYOD" : "Normal" };
        QrCodeDataService.UpdateQrCode(data)
          .then((response) => {
          })
          .catch((e) => {
            if (e.response) {
              this.$toast.error(e.response.data.respones_msg);
            }
          });
      },
      CopyProfileContent(content) {
        navigator.clipboard.writeText(content);
        this.$toast.success("text copied");
      },
      async downloadQrCode() {
        let base64;
        if (this.show_byod_qr_code) {
          base64 = this.barcode;
        } else {
          base64 = await this.getBase64FromUrl(this.barcode);
        }
        let a = document.createElement("a"); //Create <a>
        a.href = base64; //Image Base64 Goes here
        a.download = "QRCode.png"; //File name Here
        a.click(); //Downloaded file
      },
      async getBase64FromUrl(url) {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
      }
  },
  beforeMount() {
    this.GetQrCodeList();
  },
}
</script>
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-success" @click="$router.push({ path: 'admin-reseller-create'})">Create Reseller</md-button>
      </div>
      <md-card>
        <md-card-content>
        <p v-if="tableData.length == 0">No data found.</p>
          <md-table v-if="tableData.length > 0" v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="Email">{{ item.email }} <br>
                <small class="text-info"><b>Total Customers: {{ item.totalCustomers }}</b></small><br>
                <small class="text-warning"><b>Total Devices: {{ item.totalDevices }}</b></small>
              </md-table-cell>
              <md-table-cell md-label="Periodicity">{{ item.periodicity }}</md-table-cell>
              <md-table-cell md-label="Invoice Type">{{ item.invoiceType }}</md-table-cell>
              <md-table-cell md-label="Price">{{ item.devicePrice }}
                <span v-if="item.discountType">
                  <br><small><b>{{ item.discountType }} : {{ item.discountValue }}</b></small>
                </span>
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: block ruby;">
                  <md-button class="md-just-icon md-info m-0 md-simple" @click="$router.push({ path: '/admin-reseller-edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                  <md-button class="md-just-icon md-primary m-0 md-simple" @click="$router.push({ path: 'admin-invoices-list', query: { id: item._id }})"><md-icon>attach_money</md-icon></md-button>
                  <md-button class="md-just-icon md-danger m-0 md-simple" @click="deleteUser(item._id)"><md-icon>close</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";
import Swal from "sweetalert2";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {

      resellerList() {
        let data = {};
        SuperUserService.superUserList(data)
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      
      deleteUser(id) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = { "id" : id };
            SuperUserService.superUserDelete(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.resellerList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
      },
  },
  beforeMount() {
    this.resellerList();
  },
};
</script>

<style>
</style>

import http from "../../http-common-super-admin";

class DbDataService {
    DeviceList(data) {
      return http.get("/devices/list", { params: data });
    }
    FetchUserDetail(serialNumber, dbName) {
      return http.get("/devices/user/detail?serialNumber="+serialNumber+"&dbName="+dbName);
    }
    SyncDevices(data) {
      return http.get("/devices/sync", { params: data });
    }
}

export default new DbDataService();
<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-18 md-small-size-100 img-center">
              <div class="android-img">
                <img :src="android" />
              </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 center">
            <ul class="m-b-0 ul-text-format">
              <li>
                Remotely manage Corporate-Owned and Employee-Owned Devices.
              </li>
              <li>
                Convert the device into a Dedicated Kiosk, or Lockdown with
                specific apps.
              </li>
              <li>
                Secure Work Apps on personal devices with Android Container.
              </li>
              <li>
                Automatic App Installs, Remote Email Configuration and a bunch
                of policies.
              </li>
            </ul>
          </div>
          <div class="md-layout-item md-size-30 md-small-size-100 config-btn">
            <md-button
              class="md-success"
              @click="$router.push({ path: 'configure-android'})"
              >Configure</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-18 md-small-size-100 img-center">
              <div class="android-img">
                <img :src="ios" />
              </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 center">
            <!--<h4 class="text-danger center"><b>Coming Soon</b></h4>-->
            <ul class="m-b-0 ul-text-format">
              <li>Remotely manage iOS and Mac Devices, Supervised and non-Supervised.</li>
              <li>Turn the device into a Dedicated Kiosk, or Lockdown with specific apps.</li>
              <li>Secure Work Apps with Managed App Distribution and configurations.</li>
            </ul>
          </div>
          <div class="md-layout-item md-size-30 md-small-size-100 config-btn">
            <md-button
              class="md-success"
              @click="$router.push({ path: 'configure-ios'})"
              >Configure</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-18 md-small-size-100 img-center">
              <div class="android-img">
                <img :src="chrome" />
              </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 center">
            <ul class="m-b-0 ul-text-format">
              <li>
                Seamless and easy large scale provisioning of Chrome OS devices.
              </li>
              <li>
                Register devices to your Google workspace Account and assign them to users to manage them via MDM.
              </li>
              <li>
                Automatic App Installs, Remote Email Configuration and a bunch
                of policies.
              </li>
            </ul>
          </div>
          <div class="md-layout-item md-size-30 md-small-size-100 config-btn">
            <md-button
              class="md-success"
              @click="$router.push({ path: 'configure-chrome'})"
              >Configure</md-button
            >
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
export default {
  props: {
    android: {
      type: String,
      default: "./img/android.png",
    },
    ios: {
      type: String,
      default: "./img/Apple-Logo.png",
    },
    chrome: {
      type: String,
      default: "./img/chromeos.png",
    }
  },
  data() {
    return {
      
    }
  }
}
</script>

<style>
.config-btn {
  text-align: center;
  /*writing-mode: vertical-rl;*/
  margin-top: 6%;
}
.m-b-0 {
    margin-bottom: 0px !important;
}
.ul-text-format {
    font-size: 14px;
    font-weight: 400;
}
.img-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.center {
    margin: auto;
    width: 50%;
    padding: 10px;
}
</style>
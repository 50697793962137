<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu></user-menu>
      <mobile-menu>
        
      </mobile-menu>
      <template slot="links">
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Db List', icon: 'storage', path: '/admin-db-list' }"></sidebar-item>
        <sidebar-item :link="{ name: 'User List', icon: 'group', path: '/admin-user-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Resellers', icon: 'groups', path: '/admin-reseller-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Invoices', icon: 'attach_money', path: '/admin-invoices-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Devices List', icon: 'devices', path: '/admin-device-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Database Restore', icon: 'storage', path: '/admin-restore-db' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Domains List', icon: 'domain', path: '/admin-domains-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'List Email Configuration', icon: 'email', path: '/admin-email-configuration-list' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Webinars', icon: 'groups', path: '/admin-webinars' }"></sidebar-item>
        <sidebar-item v-if="isSuperAdmin"  :link="{ name: 'Logs', icon: 'description' }">
          <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Enroll Device', path: '/logs?directory=enroll-device' }"></sidebar-item>
          <sidebar-item v-if="isSuperAdmin" :link="{ name: 'Payments', path: '/logs?directory=payments' }"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./SuperAdminTopNavbar.vue";
import ContentFooter from "./SuperAdminContentFooter.vue";
import MobileMenu from "./Extra/SuperAdminMobileMenu.vue";
//import FixedPlugin from "../../FixedPlugin.vue";
import UserMenu from "./Extra/SuperAdminUserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import UserDataService from "../../../../services/SuperAdmin/UsersDataService";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    //FixedPlugin,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      isSuperAdmin: false,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          if (response.data.data.role == "SuperAdmin") {
            this.isSuperAdmin = true;
          } else {
            this.isSuperAdmin = false;
          }
        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
            this.$router.push('super-admin');
          }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
  beforeMount(){
    this.GetAdminDetail();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>

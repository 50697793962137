<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-button class="md-secondary f-right-padding" :disabled="isDisabled" @click="syncChromeDevices()">
          Sync<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
      <md-button class="md-warning f-right-padding" :disabled="isDisabled" @click="issueCommand('WIPE_USERS')">Wipe User</md-button>
      <md-button class="md-danger f-right-padding" :disabled="isDisabled" @click="issueCommand('REMOTE_POWERWASH')">Power Wash</md-button>
      <md-button class="md-primary f-right-padding" :disabled="isDisabled" @click="issueCommand('REBOOT')">Reboot</md-button>
      <md-button class="md-info f-right-padding" @click="actionModal = true">Action</md-button>
      <md-button class="md-success f-right-padding" @click="moveDeviceToOrgModal = true">Move Device</md-button>
      <tabs :tab-name="['Basic', 'Users']" color-button="warning">
        <template slot="tab-pane-1">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Profile</th>
                            <td>
                              <a v-if="deviceDetail.profile_id" @click="$router.push({ path: 'view-chrome-profile', query: { profile_id: deviceDetail.profile_id._id }})" href="javascript:void(0)">{{ deviceDetail.profile_id.profile_name }}</a>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Serial Number</th>
                            <td>{{ deviceDetail.serialNumber }}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{{ deviceDetail.state }}</td>
                          </tr>
                          <tr>
                            <th>OrgUnit Path</th>
                            <td>{{ deviceDetail.json_response.orgUnitPath }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Enrollment</th>
                            <td>{{ HumanReadableDateFormat(deviceDetail.enrollmentTime) }}</td>
                          </tr>
                          <tr>
                            <th>Last Policy Sync</th>
                            <td>{{ HumanReadableDateFormat(deviceDetail.lastPolicySyncTime) }}</td>
                          </tr>
                            <tr>
                            <th>Model</th>
                            <td>{{ deviceDetail.hardwareInfo.model }}</td>
                          </tr>    
                            <tr>
                                <th>OS Version</th>
                                <td>{{ deviceDetail.hardwareInfo.hardware }}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-2">
          <md-card>
              <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <h4 v-if="recentUsers.length == 0">No history found</h4>
                      <md-table v-else v-model="recentUsers" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                          <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                    </div>
            </md-card-content>
          </md-card>
        </template>
      </tabs>
    </div>
    <!-- Models -->
      <template>
          <modal v-if="actionModal" @close="actionModal = false">
            <template slot="header">
              <h4 class="modal-title">Device Action</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="actionModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50"> 
                    <md-field>
                      <select v-model="actionType" class="browser-default custom-select" style="padding: 9px;border: none;">
                        <option value="">Action</option>
                        <option value="deprovision">Deprovision</option>
                        <option value="disable">Disable</option>
                        <option value="reenable">Reenable</option>
                        <option value="pre_provisioned_disable">Pre Provisioned Disable</option>
                        <option value="pre_provisioned_reenable">Pre Provisioned Reenable</option>
                      </select>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50" v-show="actionType == 'deprovision'">
                    <md-field>
                      <select v-model="deprovisionReason" class="browser-default custom-select" style="padding: 9px;border: none;">
                        <option value="">Deprovision Reason</option>
                        <option value="different_model_replacement">Different Model Replacement</option>
                        <option value="retiring_device">Retiring Device</option>
                        <option value="same_model_replacement">Same_model Replacement</option>
                        <option value="upgrade_transfer">Upgrade Transfer</option>
                      </select>
                    </md-field>
                  </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="actionModal = false">Close</md-button>
              <md-button class="md-success mt-1" :disabled="isDisabled" @click="deviceAction()">Apply</md-button>
            </template>
          </modal>
      </template>

      <!-- Move Device Models -->
      <template>
          <modal v-if="moveDeviceToOrgModal" @close="moveDeviceToOrgModal = false">
            <template slot="header">
              <h4 class="modal-title">Move Device</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="moveDeviceToOrgModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50"> 
                    <md-field>
                      <select v-model="newOrg" class="browser-default custom-select" style="padding: 9px;border: none;">
                        <option value="">Select Org Unit</option>
                        <option v-for="org in orgUnitList" v-bind:key="org.orgUnitId" :value="org.orgUnitPath">{{ org.orgUnitPath }}</option>
                      </select>
                    </md-field>
                  </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="moveDeviceToOrgModal = false">Close</md-button>
              <md-button class="md-success mt-1" :disabled="isDisabled" @click="moveDeviceToOrg()">Move</md-button>
            </template>
          </modal>
      </template>
  </div>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import ChromeDataService from "../../services/ChromeDataService";
import { Tabs } from "@/components";
import { Modal } from "@/components";

export default {
  components: {
    Tabs,
    Modal
  },
  data() {
    return {
      loginUserEmail: "",
      deviceDetail: {},
      recentUsers:[],
      isDisabled: false,
      
      actionModal: false,
      actionType:"",
      deprovisionReason:"",

      moveDeviceToOrgModal: false,
      newOrg:"",
      orgUnitList:[],
    }
  },
  methods: {
    GetDeviceDetail() {
      DeviceProceDataService.GetDeviceDetail(this.$route.query.device_id)
        .then((response) => {
          this.deviceDetail = response.data.data; 
          if (this.deviceDetail.json_response.hasOwnProperty("recentUsers")) {
            this.recentUsers = this.deviceDetail.json_response.recentUsers
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    syncChromeDevices() {
      this.isDisabled = true;
      ChromeDataService.syncChromeDevices()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    issueCommand(command) {
      this.isDisabled = true;
      let data = {
        commandType: command,
        deviceId: this.deviceDetail.name
      }
      ChromeDataService.issueCommand(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deviceAction() {
      this.isDisabled = true;
      let data = {
        deviceId: this.deviceDetail.name,
        action: this.actionType,
        deprovisionReason: this.deprovisionReason
      }
      ChromeDataService.deviceAction(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    getOrgUnitList() {
      
      ChromeDataService.getOrgUnitList()
        .then((response) => {
          this.orgUnitList = response.data.data; 
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    moveDeviceToOrg() {
      this.isDisabled = true;
      let data = {
        orgUnitPath: this.newOrg,
        deviceIds: [this.deviceDetail.name]
      }
      ChromeDataService.moveDeviceToOrg(data)
        .then((response) => {
            this.isDisabled = false;
            this.moveDeviceToOrgModal = false;
            this.GetDeviceDetail();
            this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.moveDeviceToOrgModal = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetDeviceDetail();
    this.getOrgUnitList();
  },
};
</script>
<style scoped>
.pt-10 {
  padding-top: 10px;
}
.w3-responsive {
    display: block;
    overflow-x: auto;
}
.ws-table-all {
    /*border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;*/
    width: 100%;
    display: table;
}
.ws-table-all tr, .ws-table-all td {
    /*border: 1px solid black;
    border-collapse: collapse;*/
}
.ws-table-all td {
    padding: 5px;
}
.ws-table-all th {
    text-align: left;
}
</style>
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-button v-if="device_detail.fcm_token" class="md-warning f-right-padding" :disabled="isDisabled" @click="sendFetchLogsNotification()">Fetch Logs</md-button>&nbsp;
      <md-button v-if="device_detail.fcm_token" class="md-primary f-right-padding" :disabled="isDisabled" @click="sendMessageModal = true">Send Message</md-button>&nbsp;
      <md-button v-if="loginUserEmail != 'apppcm1400@gmail.com'" class="md-success f-right-padding" :disabled="isDisabled" @click="SyncDevices()">Sync Device</md-button>&nbsp;
      <tabs :tab-name="['Basic', 'Hardware & Software', 'Network & Memory', 'Applications', 'Issues', 'Deleted History']" color-button="warning">
        <template slot="tab-pane-1">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Profile</th>
                            <td>
                              <a v-if="device_detail.profile_id" @click="$router.push({ path: 'view-profiles', query: { profile_id: device_detail.profile_id._id }})" href="javascript:void(0)">{{ device_detail.profile_id.profile_name }}</a>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <th>User</th>
                            <td>{{ userName }}</td>
                          </tr>
                          <tr>
                            <th>Serial Number</th>
                            <td>{{ device_detail.serialNumber }}</td>
                          </tr>
                          <tr>
                            <th>Battery Level</th>
                            <td>{{ batteryInfo.batteryLevel }}</td>
                          </tr>
                          <tr>
                            <th>Battery Checked At</th>
                            <td>{{ HumanReadableDateFormat(batteryInfo.batteryLevelTimeCreation) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Enrollment</th>
                            <td>{{ HumanReadableDateFormat(device_detail.enrollmentTime) }}</td>
                          </tr>
                          <tr>
                            <th>Last Status Report</th>
                            <td>{{ HumanReadableDateFormat(device_detail.lastStatusReportTime) }}</td>
                          </tr>
                          <tr>
                            <th>Last Policy Sync</th>
                            <td>{{ HumanReadableDateFormat(device_detail.lastPolicySyncTime) }}</td>
                          </tr>
                          <tr>
                            <th>Applied State</th>
                            <td>{{ device_detail.appliedState }}</td>
                          </tr>
                          <tr>
                            <th>Management Mode</th>
                            <td>{{ device_detail.managementMode }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-2">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-header>
                    <h4 class="title"><b>Hardware Details</b></h4>
                  </md-card-header>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Brand</th>
                            <td>{{ device_detail.hardwareInfo.brand }}</td>
                          </tr>
                          <tr>
                            <th>Hardware</th>
                            <td>{{ device_detail.hardwareInfo.hardware }}</td>
                          </tr>
                          <tr>
                            <th>Device Baseband Version</th>
                            <td>{{ device_detail.hardwareInfo.deviceBasebandVersion }}</td>
                          </tr>
                          <tr>
                            <th>Manufacturer</th>
                            <td>{{ device_detail.hardwareInfo.manufacturer }}</td>
                          </tr>
                          <tr>
                            <th>Serial Number</th>
                            <td>{{ device_detail.hardwareInfo.serialNumber }}</td>
                          </tr>
                          <tr>
                            <th>Model</th>
                            <td>{{ device_detail.hardwareInfo.model }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-header>
                    <h4 class="title"><b>Software Details</b></h4>
                  </md-card-header>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Android Version</th>
                            <td>{{ softwareInfo.androidVersion }}</td>
                          </tr>
                          <tr>
                            <th>Android Build Number</th>
                            <td>{{ softwareInfo.androidBuildNumber }}</td>
                          </tr>
                          <tr>
                            <th>Device Kernel Version</th>
                            <td>{{ softwareInfo.deviceKernelVersion }}</td>
                          </tr>
                          <tr>
                            <th>Bootloader Version</th>
                            <td>{{ softwareInfo.bootloaderVersion }}</td>
                          </tr>
                          <tr>
                            <th>Primary Language Code</th>
                            <td>{{ softwareInfo.primaryLanguageCode }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-3">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-header>
                    <h4 class="title"><b>Network Details</b></h4>
                  </md-card-header>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">IMEI</th>
                            <td>{{ networkInfo.imei }}</td>
                          </tr>
                          <tr>
                            <th>WiFi Mac Address</th>
                            <td>{{ networkInfo.wifiMacAddress }}</td>
                          </tr>
                          <tr>
                            <th>Network Operator Name</th>
                            <td>{{ networkInfo.networkOperatorName }}</td>
                          </tr>
                          <tr v-if="networkInfo.telephonyInfos">
                            <th>Phone Number</th>
                            <td>{{ networkInfo.telephonyInfos[0].phoneNumber }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-header>
                    <h4 class="title"><b>Memory Details</b></h4>
                  </md-card-header>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Total Ram</th>
                            <td>{{ device_detail.memoryInfo.totalRam }}</td>
                          </tr>
                          <tr>
                            <th>Total Internal Storage</th>
                            <td>{{ device_detail.memoryInfo.totalInternalStorage }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-4">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <md-card>
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50">
                        <md-field>
                          <md-select class="box-textbox" placeholder="Search By State" v-model="search_state">
                              <md-option value="">State</md-option>
                              <md-option v-for="state in applicationStatArray" v-bind:key="state" :value="state">
                                {{ state }}
                              </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-50">
                        <md-field>
                          <md-select class="box-textbox" placeholder="Source" v-model="search_source">
                              <md-option value="">Source</md-option>
                              <md-option v-for="source in applicationSourceArray" v-bind:key="source" :value="source">
                                {{ ReplaceString(source,"_"," ") }}
                              </md-option>
                          </md-select>
                        </md-field>
                      </div>
                  </div>
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50">
                        <md-field>
                          <md-input type="text" class="box-textbox" placeholder="Application Title" v-model="search_package_name"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-50">
                        <md-field>
                          <md-input type="text" class="box-textbox" placeholder="Package Name" v-model="search_package_id"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-10">
                          <md-button class="md-primary" :disabled="isDisabled"  @click="FilterApplications()">Search</md-button> 
                      </div>
                  </div>
                  <hr>
                  <md-card-content>
                    <md-table :value="queriedData" class="paginated-table table-striped table-hover table-full-width" table-header-color="green" md-fixed-header>
                      <md-table-toolbar>
                        <md-field>
                          <label for="pages">Per page</label>
                          <md-select v-model="pagination.perPage" name="pages">
                            <md-option
                              v-for="item in pagination.perPageOptions"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                              {{ item }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </md-table-toolbar>
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Display Name">{{ item.displayName }}
                          <small>( {{ item.packageName }} )</small>
                        </md-table-cell>
                        <md-table-cell md-label="Source">{{ ReplaceString(item.applicationSource, "_", " ") }}</md-table-cell>
                        <md-table-cell md-label="State / Version Name/ Version Code">{{ item.state }}
                          <small>( {{ item.versionName }} )</small>
                          <small>( {{ item.versionCode }} )</small>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </md-card-content>
                  <md-card-actions md-alignment="space-between">
                    <div class="">
                      <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                      </p>
                    </div>
                    <pagination
                      class="pagination-no-border pagination-success"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
                    >
                    </pagination>
                  </md-card-actions>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-5">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <md-card>
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50">
                        <md-field>
                          <md-input type="text" class="box-textbox" placeholder="Package ID" v-model="nonCompliancePackageId"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-10">
                          <md-button class="md-primary" :disabled="isDisabled"  @click="filterNonComplianceDetails()">Search</md-button> 
                      </div>
                  </div>
                  <hr>
                  <md-card-content>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100 md-small-size-100">
                        <h4 v-if="nonComplianceDetails.length == 0">No data found</h4>
                        <md-table v-else v-model="nonComplianceDetails" table-header-color="green">
                          <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Package Name">{{ item.packageName }}</md-table-cell>
                            <md-table-cell md-label="Setting Name">{{ item.settingName }}</md-table-cell>
                            <md-table-cell md-label="Reason">{{ item.nonComplianceReason }}</md-table-cell>
                            <md-table-cell md-label="Installation Failer Reason">{{ item.installationFailureReason }}</md-table-cell>
                          </md-table-row>
                        </md-table>
                      </div>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-6">
          <md-card>
              <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <h4 v-if="deletedDevices.length == 0">No history found</h4>
                      <md-table v-else v-model="deletedDevices" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Profile">
                            <a v-if="item.profile_id" href="javascript:void(0)" @click="$router.push({ path: 'view-profiles', query: { profile_id: device_detail.profile_id._id }})">{{ item.profile_id.profile_name }}</a>
                            <span v-else>-</span>
                          </md-table-cell>
                          <md-table-cell md-label="Serial Number">{{ item.serialNumber }}</md-table-cell>
                          <md-table-cell md-label="Deleted At">{{ HumanReadableDateFormat(item.deleted_at) }}</md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                  </div>
            </md-card-content>
          </md-card>
        </template>
      </tabs>
    </div>
    <!--sendMessageModal Modal -->
    <template>
        <modal v-if="sendMessageModal" @close="sendMessageModal = false">
          <template slot="header">
            <h4 class="modal-title">Send Message To Device</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="sendMessageModal = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
          <div class="box-body">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-field>
                  <label>Message</label>
                  <md-input type="text" v-model="fcmMessage"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="sendMessageModal = false">Close</md-button>
            <md-button class="md-success mt-1" type="submit" :disabled="isDisabled" @click="sendFcmPush()">Send</md-button>
          </template>
        </modal>
    </template>
  </div>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import UserDataService from "../../services/UsersDataService";
import { Modal, Tabs } from "@/components";
import { Pagination } from "@/components";

export default {
  components: {
    Tabs,
    Pagination,
    Modal,
  },
  data() {
    return {
      loginUserEmail: "",
      device_detail: {},
      batteryInfoArray: [],
      applicationArray: [],
      applicationArrayBackup: [],
      applicationStatArray: [],
      applicationSourceArray: [],
      search_state: "",
      search_source: "",
      search_package_name: "",
      search_package_id: "",
      isDisabled: false,

      profileApplications: [],
      nonComplianceDetails:[],
      nonComplianceDetailsBackup: [],
      nonCompliancePackageId: "",

      //Deleted Devices
      deletedDevices: [],

      sendMessageModal: false,
      fcmMessage:"",

      // Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],
    }
  },
  computed: {
    userName: function () {
      return (this.device_detail.user_id) ? this.device_detail.user_id.user_name : "-"
    },
    softwareInfo: function() {
      return {
        androidVersion: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.androidVersion : "-",
        androidBuildNumber: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.androidBuildNumber : "-",
        deviceKernelVersion: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.deviceKernelVersion : "-",
        bootloaderVersion: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.bootloaderVersion : "-",
        primaryLanguageCode: (this.device_detail.json_response.softwareInfo) ? this.device_detail.json_response.softwareInfo.primaryLanguageCode : "-",
      }
    },
    networkInfo: function() {
      return {
        imei: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.imei : "-",
        wifiMacAddress: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.wifiMacAddress : "-",
        networkOperatorName: (this.device_detail.json_response.networkInfo) ? this.device_detail.json_response.networkInfo.networkOperatorName : "-",
        telephonyInfos: (this.device_detail.json_response.networkInfo.hasOwnProperty("telephonyInfos")) ? this.device_detail.json_response.networkInfo.telephonyInfos : null,
      }
    },
    batteryInfo: function() {
      return {
        batteryLevel: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].batteryLevel : "-",
        batteryLevelTimeCreation: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].createTime : "-",
        batteryEvent: (this.batteryInfoArray.length > 0) ? this.batteryInfoArray[this.batteryInfoArray.length-1].eventType : "-",
      }
    },

    // Pagination
    queriedData() {
      let result = this.applicationArray;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.applicationArray.length;
    },
  },
  methods: {
    GetDeviceDetail() {
      DeviceProceDataService.GetDeviceDetail(this.$route.query.device_id)
        .then((response) => {
          this.device_detail = response.data.data;
          
          if (this.device_detail.json_response.nonComplianceDetails) {
            this.nonComplianceDetails = this.device_detail.json_response.nonComplianceDetails;
            this.nonComplianceDetailsBackup = this.device_detail.json_response.nonComplianceDetails;
          }

          this.GetProfileApplicationList(this.device_detail.profile_id._id);

          this.batteryInfoArray = this.device_detail.json_response.powerManagementEvents.filter(i => i.eventType == "BATTERY_LEVEL_COLLECTED");
          if (this.device_detail.json_response.applicationReports) {
            this.applicationArray = this.device_detail.json_response.applicationReports;
            this.applicationArrayBackup = this.device_detail.json_response.applicationReports;
            if (this.applicationArray.length > 0) {
              this.applicationArray.forEach(application => {
                if (! this.applicationStatArray.includes(application.state)){
                  this.applicationStatArray.push(application.state)
                }

                if (! this.applicationSourceArray.includes(application.applicationSource) && application.applicationSource != undefined) {
                  this.applicationSourceArray.push(application.applicationSource)
                }
              });
            }
          }
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
    FilterApplications() {
      this.applicationArray = this.applicationArrayBackup;
      if (this.search_source) {
        this.applicationArray = this.applicationArray.filter(i => i.applicationSource == this.search_source)          
      }
      if (this.search_state) {
        this.applicationArray = this.applicationArray.filter(i => i.state == this.search_state);        
      } 
      if (this.search_package_name) {
        this.applicationArray = this.applicationArray.filter(i => 
          (i.displayName)  ? i.displayName.indexOf(this.search_package_name) > -1 : false
          //i.displayName.indexOf(this.search_package_name) > -1
        );        
      }
      if (this.search_package_id) {
        this.applicationArray = this.applicationArray.filter(i => i.packageName.indexOf(this.search_package_id) > -1);        
      }
    },

    filterNonComplianceDetails() {
      this.nonComplianceDetails = this.nonComplianceDetailsBackup;
      if (this.nonCompliancePackageId) {
        this.nonComplianceDetails = this.nonComplianceDetails.filter(i => i.packageName.indexOf(this.nonCompliancePackageId) > -1);
      }
    },

    SyncDevices() {
      this.isDisabled = true;
      DeviceProceDataService.SyncDevices(this.$route.query.device_id)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.loginUserEmail = response.data.data.email;
          
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    deletedHistory() {
      let data = {
        id: this.$route.query.device_id, 
      }
      DeviceProceDataService.deletedHistory(data)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.deletedDevices = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    GetProfileApplicationList(profileId) {
      this.profileApplications = [];
      let nonComplianceDetails = this.nonComplianceDetails;
      let existApplications = [];

      DeviceProceDataService.GetProfileApplicationList(
        profileId, ""
      )
        .then((response) => {
          if (response.data.data) {
            this.profileApplications = response.data.data;
            if (this.profileApplications.length > 0) {
              this.profileApplications.forEach(function (item, index) {
                let applicationExist = nonComplianceDetails.some(el => el.packageName === item.package_name);
                if (applicationExist) {
                  //Remove from the array list
                  existApplications.push(item.package_name)
                }
              });
              nonComplianceDetails = nonComplianceDetails.filter(function(obj) {
                  return this.has(obj.packageName);
              }, new Set(existApplications.map(obj => obj)));
              this.nonComplianceDetails = nonComplianceDetails;
              this.nonComplianceDetailsBackup = nonComplianceDetails;
            }
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    sendFcmPush() {
      this.isDisabled = true;
      let data = {
        id: this.$route.query.device_id,
        message: this.fcmMessage
      }
      DeviceProceDataService.sendFcmPush(data)
        .then((response) => {
          this.isDisabled = false;
          this.sendMessageModal = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async sendFetchLogsNotification() {
      this.isDisabled = true;
      let data = {
        id: this.$route.query.device_id,
      }
      await DeviceProceDataService.sendFetchLogsNotification(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetDeviceDetail();
    this.GetAdminDetail();
    this.deletedHistory();
  },
  mounted: function () {
    this.$nextTick(() => {
      const that = this;
      $("#4").on("click", function() {
        alert(121);
      });
    })
  }
};
</script>
<style scoped>
.pt-10 {
  padding-top: 10px;
}
.w3-responsive {
    display: block;
    overflow-x: auto;
}
.ws-table-all {
    /*border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;*/
    width: 100%;
    display: table;
}
.ws-table-all tr, .ws-table-all td {
    /*border: 1px solid black;
    border-collapse: collapse;*/
}
.ws-table-all td {
    padding: 5px;
}
.ws-table-all th {
    text-align: left;
}
</style>
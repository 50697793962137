<template>
    <div class="md-layout-item md-size-100">
        <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="createSwitchProfile()">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="webinarForm">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Title</label>
                                <md-input type="text" v-model="title"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Type</label>
                                <md-select v-model="type">
                                    <md-option value="Daily">Daily</md-option>
                                    <md-option value="Weekly">Weekly</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Timezone</label>
                                <select v-model="timezone" id="timezoneDropdown">
                                    <option v-for="timezone in timezoneArray()" :key="timezone" :value="timezone">{{ timezone }}</option>
                                </select>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <select v-model="primaryProfile" :id="'pirmary-profile'">
                                <option v-for="profile in androidProfiles" :key="'p-'+profile._id" :value="profile._id">
                                    {{ profile.profile_name }}
                                </option>
                            </select>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <select v-model="secondaryProfile" :id="'secondary-profile'">
                                <option v-for="profile in androidProfiles" :key="'s-'+profile._id" :value="profile._id">
                                    {{ profile.profile_name }}
                                </option>
                            </select>
                        </div>
                    </div><br>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <label><b>Start Time</b></label>
                            <md-input type="time" class="form-control" @change="onTimeChange(true)" id="startTime" v-model="startTime"></md-input>
                        </div>
                        <div class="md-layout-item md-size-50" v-if="startTime12Hours && endTime12Hours">
                            <ul>
                                <li><b>{{ startTime12Hours }} to {{ endTime12Hours }} : {{ primaryProfileName }}</b></li>
                                <li><b>{{ endTime12Hours }} to {{ startTime12Hours }} : {{ secondaryProfileName }}</b></li>
                            </ul>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <label><b>End Time</b></label>
                            <md-input type="time" class="form-control" @change="onTimeChange(false)" id="endTime" v-model="endTime"></md-input>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import SwitchProfileDataService from "../../services/SwitchProfileDataService";

export default {
    data() {
        return {
            isDisabled: false,
            title:"Default",
            type:"Daily",
            primaryProfile:"",
            primaryProfileName:"",
            secondaryProfile:"",
            secondaryProfileName:"",
            startTime:"",
            endTime:"",
            androidProfiles: [],
            startTime12Hours:"",
            endTime12Hours:"",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    },
    methods: {
        getAndriodDeviceProfileList() {
            DeviceProceDataService.GetDeviceProfileList({ search_profile_type: "Android" })
                .then((response) => {
                    this.androidProfiles = response.data.data;
                })
                .catch((e) => {
                    this.$toast.error(e);
                });
        },
        onTimeChange(startTime) {
            let inputEle;
            if (startTime == true) {
                inputEle = document.getElementById('startTime');
            } else {
                inputEle = document.getElementById('endTime');
            }
            var timeSplit = inputEle.value.split(':'),
                hours,
                minutes,
                meridian;
            hours = timeSplit[0];
            minutes = timeSplit[1];
            if (hours > 12) {
                meridian = 'PM';
                hours -= 12;
            } else if (hours < 12) {
                meridian = 'AM';
                if (hours == 0) {
                hours = 12;
                }
            } else {
                meridian = 'PM';
            }
            if (startTime == true) {
                this.startTime12Hours = hours + ':' + minutes + ' ' + meridian;
            } else {
                this.endTime12Hours = hours + ':' + minutes + ' ' + meridian;
            }
        },
        createSwitchProfile() {
            this.isDisabled =true;
            let data = { title: this.title, type: this.type, primaryProfileId: this.primaryProfile, timezone: this.timezone,
            secondaryProfileId: this.secondaryProfile, startTime: this.startTime, endTime: this.endTime,
            primaryProfileMessage: this.startTime12Hours + " to " + this.endTime12Hours + " : " + this.primaryProfileName,
            secondaryProfileMessage: this.endTime12Hours + " to " + this.startTime12Hours + " : " + this.secondaryProfileName };

            SwitchProfileDataService.create(data)
            .then((response) => {
                this.isDisabled =false;
                this.$toast.success(response.data.respones_msg);
                this.$router.push("/list-profile-switch");
            })
            .catch((e) => {
                this.isDisabled =false;
                this.$toast.error(e.response.data.respones_msg);
            });
        }
    },
    beforeMount() {
        this.getAndriodDeviceProfileList();
    },
    mounted() {
        $('#pirmary-profile').select2({
            placeholder: "Select Primary Profile",
            theme: "classic"
        }).on('select2:select', (e) => {
            this.primaryProfile = e.params.data.id;
            this.primaryProfileName = e.params.data.text;
        });
        $('#secondary-profile').select2({
            placeholder: "Select Secondary Profile",
            theme: "classic"
        }).on('select2:select', (e) => {
            this.secondaryProfile = e.params.data.id;
            this.secondaryProfileName = e.params.data.text;
        });
        $('#timezoneDropdown').select2({
            placeholder: "Select Timezone",
            theme: "classic"
        }).on('select2:select', (e) => {
            this.timezone = e.params.data.id;
        });
    }
}
</script>
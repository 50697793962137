<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
    <div style="float: right">
      <md-button type="submit" class="md-success" @click="classicModal = true">Create Profile</md-button>
      <md-button v-if="loginUserDetail && loginUserDetail.tokenFileContent" class="md-secondary" :disabled="isDisabled" @click="syncChromeOrg()">
          Sync Chrome Organizations<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
    </div>
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Name" v-model="search_profile_name"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <md-select class="box-textbox" placeholder="Type" v-model="search_profile_type">
                      <md-option value="">Type</md-option>
                      <md-option value="Android">Android</md-option>
                      <md-option value="Ios">Ios</md-option>
                      <md-option value="Chromebook">Chromebook</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-button class="md-primary" :disabled="isDisabled"  @click="GetDeviceProfileList()">Search</md-button> 
              </div>
          </div>
          <md-table v-model="tableData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="profile_name">
                <a v-if="item.profile_type == 'Android'" @click="$router.push({ path: 'view-profiles', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.profile_name }}</a>
                <a v-else-if="item.profile_type == 'Ios'" @click="$router.push({ path: 'view-ios-profiles', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.profile_name }}</a>
                <a v-else-if="item.profile_type == 'Chromebook'" @click="$router.push({ path: 'view-chrome-profile', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.profile_name }}</a>
                &nbsp;<span class="expired" v-if="item.is_custom_android_profile">Custom</span>
              </md-table-cell>              
              <md-table-cell md-label="Type" md-sort-by="profile_type">
                <!--<div class="circular--portrait">
                  <img :src="andriodLogo" />
                </div>-->
                <p class="badge-blue" v-if="item.profile_type == 'Android'">Android</p>
                <p class="badge-red" v-else-if="item.profile_type == 'Ios'">Ios</p>
                <p class="badge-chrome-book" v-else-if="item.profile_type == 'Chromebook'">Chromebook</p>
              </md-table-cell>
              <md-table-cell md-label="Devices">{{ item.totalDevices }}</md-table-cell>
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: block ruby;" v-if="item.profile_type == 'Android'">
                  <md-button class="md-just-icon md-info md-simple" @click="$router.push({ path: 'view-profiles', query: { profile_id: item._id }})"><md-icon>edit</md-icon></md-button>
                  <md-button class="md-just-icon md-success md-simple" @click="OpenCopyProfileModal(item._id)"><i class="material-icons">content_copy</i></md-button>
                  <md-button class="md-just-icon md-danger md-simple" @click="DeleteProfile(item._id)"><md-icon>close</md-icon></md-button>
                </div>
                <div style="display: block ruby;" v-else-if="item.profile_type == 'Ios'">
                  <md-button class="md-just-icon md-info md-simple" @click="$router.push({ path: 'view-ios-profiles', query: { profile_id: item._id }})"><md-icon>edit</md-icon></md-button>&nbsp;
                  <md-button class="md-just-icon md-danger md-simple" @click="DeleteIosProfile(item._id)"><md-icon>close</md-icon></md-button>
                </div>
                <div style="display: block ruby;" v-else-if="item.profile_type == 'Chromebook'">
                  <md-button class="md-just-icon md-danger md-simple" @click="deleteChromeProfile(item._id)"><md-icon>close</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        
      </md-card>
      <!-- Create Profile Modal -->
        <template>
              <modal v-if="classicModal" @close="classicModalHide">
                <template slot="header">
                  <h4 class="modal-title">Create New Profile</h4>
                  <md-button
                    style="height: 25px"
                    class="md-simple md-just-icon md-round modal-default-button"
                    @click="classicModalHide"
                  >
                    <md-icon>clear</md-icon>
                  </md-button>
                </template>

                <template slot="body">
                  <div class="md-layout-item md-size-100">
                    <div class="md-layout">
                      <div class="md-layout-item">
                        <md-radio v-model="profile_type" value="Android" @change="toggleProfileType()"><md-icon>adb</md-icon>Android</md-radio>
                        <md-radio v-model="profile_type" value="Ios" @change="toggleProfileType()"><md-icon>phone_iphone</md-icon>Ios</md-radio>
                        <md-radio v-model="profile_type" value="Chromebook" @change="toggleProfileType()"><md-icon>laptop_chromebook</md-icon>Chromebook</md-radio>
                      </div>
                    </div>

                    <div class="md-layout">
                      <div class="md-layout-item">
                          <md-field>
                            <label>{{ profileNameLabel }}</label>
                            <md-input v-model="profile_name" type="text"></md-input>
                          </md-field>
                      </div>
                    </div>
                    <div class="md-layout" v-if="profile_type == 'Android'">
                        <div class="md-layout-item">
                            <md-switch v-model="enableProfileDisappearing">Enable Profile Disappearing</md-switch>
                            <small v-if="enableProfileDisappearing == true" class="text-danger"><b>By enabling this features, All Devices added to this profile will be unenrolled (Factory reset for Fully managed devices; Removal of work profile in case of BYOD—bring your own devices) after the set Interval in Advance Setting Tab.</b></small>
                        </div>
                    </div>
                    <!--<div class="md-layout" v-if="loginUserDetail && loginUserDetail.email == 'epsom@epsom.com'">
                      <div class="md-layout-item">
                          <md-field>
                            <label>Device Delete Interval</label>
                            <md-input v-model="deviceDeleteInterval" type="number"></md-input>
                          </md-field>
                          <small class="text-danger"><b>All Devices in this profile deleted after set Interval. Leave empty if you don't want to delete.</b></small>
                      </div>
                    </div>-->
                  </div>
                </template>

                <template slot="footer">
                  <md-button class="md-danger md-simple" @click="classicModalHide">Close</md-button>
                  <md-button class="md-success mt-1" :disabled="isDisabled" @click="CreateProfile()">
                    <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>Submit</md-button>
                </template>
              </modal>
        </template>

        <!-- Copy Profile Modal -->
        <template>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <modal v-if="CopyProfileModal" @close="CopyProfileModalHide">
                <template slot="header">
                  <h4 class="modal-title">Copy Profile</h4>
                  <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                    @click="CopyProfileModalHide">
                    <md-icon>clear</md-icon>
                  </md-button>
                </template>

                <template slot="body">
                  <div class="md-layout-item md-size-100">

                    <div class="md-layout">
                      <div class="md-layout-item">
                        <ValidationProvider
                          name="profile_name"
                          rules="required"
                          v-slot="{ passed, failed }"
                        >
                          <md-field
                            :class="[
                              { 'md-error': failed },
                              { 'md-valid': passed },
                            ]"
                          >
                            <label>Enter Profile Name</label>
                            <md-input
                              v-model="copy_profile_name"
                              type="text"
                            ></md-input>
                          </md-field>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </template>

                <template slot="footer">
                  <md-button class="md-danger md-simple" @click="CopyProfileModalHide">Close</md-button>
                  <md-button class="md-success" :disabled="isDisabled" @click="CopyProfile()">
                    <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>Copy</md-button>
                </template>
              </modal>
            </form>
          </ValidationObserver>
        </template>
    </div>
  </div>
</template>
<script>
import { Modal } from "@/components";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import IosMdmDataService from "../../services/IosMdmDataService";
import ChromeDataService from "../../services/ChromeDataService";
import UserDataService from "../../services/UsersDataService";

import Swal from "sweetalert2";
export default {
  components: {
    Modal,
  },
  props: {
    andriodLogo: {
      type: String,
      default: "./img/android.png",
    },
    iosLogo: {
      type: String,
      default: "./img/Apple-Logo.png",
    },
    chromeLogo: {
      type: String,
      default: "./img/chromeos.png",
    },
  },
  data() {
    return {
      classicModal: false,
      profile_type: true,
      profile_name: null,
      exit_passcode: null,
      tableData: [],
      copy_profile_id: null,
      copy_profile_name: null,
      CopyProfileModal: false,
      isDisabled: false,
      profileNameLabel: "Enter Profile Name",
      loginUserDetail:"",
      deviceDeleteInterval: null,
      enableProfileDisappearing: false,

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",

      //Search
      search_profile_name: "",
      search_profile_type: "",
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    classicModalHide: function () {
      this.classicModal = false;
    },
    CopyProfileModalHide: function () {
      this.CopyProfileModal = false;
    },
    GetDeviceProfileList() {
      let data = {
        profile_id: "",
        search_profile_name: this.search_profile_name,
        search_profile_type: this.search_profile_type
      }
      DeviceProceDataService.GetDeviceProfileList(data)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    CreateProfile() {
      this.isDisabled = true;
      if (this.profile_type == "Android") {
        let data = {
          profile_name: this.profile_name,
          exit_passcode: 123456,
          profile_type: this.profile_type,
          deviceDeleteInterval: this.deviceDeleteInterval,
          enableProfileDisappearing: this.enableProfileDisappearing
        };

        DeviceProceDataService.CreateProfile(data)
          .then((response) => {
            this.isDisabled = false;
            this.classicModal = false;
            this.GetDeviceProfileList();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      } else if (this.profile_type == "Ios"){
        let data = {
          profile_name: this.profile_name,
          exit_passcode: 123456,
          profile_type: "Ios",
        };

        IosMdmDataService.CreateProfile(data)
          .then((response) => {
            this.isDisabled = false;
            this.classicModal = false;
            this.GetDeviceProfileList();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      } else if (this.profile_type == "Chromebook") {
        let data = {
          profile_name: this.profile_name,
          exit_passcode: 123456,
          profile_type: "Chromebook",
        };

        ChromeDataService.createProfile(data)
          .then((response) => {
            this.isDisabled = false;
            this.classicModal = false;
            this.GetDeviceProfileList();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      } else {
        this.isDisabled = false;
        this.$toast.error("Please select profile type.");
      }
    },
    toggleProfileType() {
      if (this.profile_type == "Chromebook") {
        this.profileNameLabel = "Enter Organization Name";
      } else {
        this.profileNameLabel = "Enter Profile Name";
      }
    },
    DeleteProfile(profile_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `All devices in this profile will be factory reseted`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            profile_id : profile_id
          };
          DeviceProceDataService.DeleteProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          });
        }
      });  
    },

    DeleteIosProfile(profile_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `All devices in this profile will be factory reseted`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            profile_id : profile_id
          };
          DeviceProceDataService.DeleteIosProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          });
        }
      });  
    },

    deleteChromeProfile(profileId) {
      Swal.fire({
        title: "Are you sure?",
        text: `All devices in this organization will be factory reseted`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            profileId : profileId
          };
          ChromeDataService.deleteChromeProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.GetDeviceProfileList();
            this.isDisabled = false;
          });
        }
      });  
    },

    OpenCopyProfileModal(profile_id){
      this.copy_profile_id = profile_id;
      this.copy_profile_name = null;
      this.CopyProfileModal = true;
    },
    CopyProfile() {
      this.isDisabled = true;
      var data = {
        profile_name: this.copy_profile_name,
        profile_id: this.copy_profile_id,
      };
      
      DeviceProceDataService.CopyProfile(data)
        .then((response) => {
          this.isDisabled = false;
          this.CopyProfileModal = false;
          this.GetDeviceProfileList();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    syncChromeOrg() {
      this.isDisabled = true;
      ChromeDataService.syncChromeOrg()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceProfileList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.loginUserDetail = response.data.data;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              this.$router.push('login');
            }
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
  },
  beforeMount() {
    this.GetDeviceProfileList();
    this.GetAdminDetail();
  },
};
</script>
<style>
@import '../../assets/css/custom-responsive.css';
</style>

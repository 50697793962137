<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right;">
          <md-button class="md-success mr-2" @click=OpenModel()>Play Store</md-button>
          <md-button class="md-info mr-2" @click="CreateAppModel = true">Manual App</md-button>
          <md-button class="md-primary mr-2" @click="CreateWebAppModel = true">Create Web App</md-button>
          <md-button class="md-warning" :disabled="isDisabled" @click="SyncWebApp">
            Sync Web App
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
      </div>
      <md-card>
          <md-card-content>
              <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                      <md-field>
                          <md-select class="box-textbox" placeholder="Country" v-model="country">
                              <md-option v-for="code in GetCountryList()" v-bind:key="code.code"
                                  :value="code.iso">{{ code.iso + ' - ' + code.country }}</md-option>
                          </md-select>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                      <md-field>
                          <md-input type="text" v-model="search_application" placeholder="Search Applications" ></md-input>
                          <button class="search_btn" :disabled="isDisabled" @click="SearchPlayStoreApp()"><i class="fa fa-search"></i></button>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                      <div v-if="play_store_applications.length > 0" class="scrollit">
                          <div class="app-listing box-shadow" v-if="play_store_applications">
                              <div class="screencellbook" v-for="app in play_store_applications" :key="app.appId">
                              <img :src="app.icon">
                              <a class="badge-icon" href="javascript:void(0);" @click="AddToProfileApplication(app)">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                              </a>
                              <span class="apptext" :title="app.title">{{ app.title }}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </md-card-content>
      </md-card>
      <md-card>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Package Name" md-sort-by="package_name">
                <img :src="item.app_icon" style="height: 50px;width: 50px;" /> 
                <span v-if="!item.is_web_app"> {{ item.package_name }} </span>
                <span v-else> {{ item.application_details.title }} </span>
              </md-table-cell>
              <!--<md-table-cell md-label="Product Id">{{ item.product_id }}</md-table-cell>-->
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button class="md-just-icon md-danger md-simple" @click="DeleteManualApp(item.package_name)"><md-icon>close</md-icon></md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
        
      </md-card>
       <template>
            <modal v-if="classicModal" @close="classicModalHide" class="play-store-modal">
              <template slot="header">
                <h4 class="modal-title">Play Store Apps</h4>
                <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
                  <md-icon>clear</md-icon>
                </md-button>
              </template>
              <template slot="body">
                <div class="box-body">
                  <div class="col-md-12 table-responsive iframe-hieght" id="container"></div>
                </div>
              </template>
              <template slot="footer">
                <md-button
                  class="md-danger md-simple"
                  @click="classicModalHide"
                  >Close</md-button
                >
              </template>
            </modal>
        </template>
        <!--Create Application Modal -->
      <template>
          <modal v-if="CreateAppModel" @close="CreateAppModalHide">
            <template slot="header">
              <h4 class="modal-title">Create Application</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="CreateAppModalHide"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <label>Package Name</label>
                    <md-input type="text" v-model="create_application_package_name"></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="CreateAppModalHide">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="AddManualApp()">Save</md-button>
            </template>
          </modal>
      </template>
      <!--Create Web Application Modal -->
      <template>
          <modal v-if="CreateWebAppModel" @close="CreateWebAppModel = false">
            <template slot="header">
              <h4 class="modal-title">Create Web Application</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="CreateWebAppModel = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <!--<div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="web_app_name"></md-input>
                  </md-field>
                </div>-->
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <select style="width: 100%;height: 35px;" v-model="web_app_display_mode">
                        <option value="MINIMAL_UI" selected>MINIMAL UI</option>
                        <option value="STANDALONE">STANDALONE</option>
                        <option value="FULL_SCREEN">FULL SCREEN</option>
                    </select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Title</label>
                    <md-input type="text" v-model="web_app_title"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Start Url</label>
                    <md-input type="text" v-model="web_app_start_url"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Version Code</label>
                    <md-input type="text" v-model="web_app_version_code"></md-input>
                  </md-field>
                </div>
              </div>
              <!--<div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <select style="width: 100%;height: 35px;" v-model="web_app_display_mode">
                        <option value="MINIMAL_UI" selected>MINIMAL UI</option>
                        <option value="STANDALONE">STANDALONE</option>
                        <option value="FULL_SCREEN">FULL SCREEN</option>
                    </select>
                  </md-field>
                </div>
              </div>-->
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="CreateWebAppModel = false">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="AddWebApp()">
                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
              Save</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import { Modal } from "@/components";
import ApplicationDataService from "../../services/ApplicationDataService";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
export default {
  components: {
    Modal,
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      classicModal: false,
      profile_type: true,
      profile_name: null,
      exit_passcode: null,
      token: null,
      tableData: [],

      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      // Create App
      CreateAppModel: false,
      create_application_package_name: null,

      // Create Web App
      CreateWebAppModel: false,
      web_app_name: "",
      web_app_title: "",
      web_app_start_url: "",
      web_app_version_code: "",
      web_app_display_mode: "MINIMAL_UI",

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",

      // Search Section
      search_application: "",
      country: "us",
      play_store_applications: [],
      profile_applications: [],

    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    classicModalHide: function () {
      this.classicModal = false;
      this.GetApplicationList();
    },
    CreateAppModalHide: function () {
      this.CreateAppModel = false;
      this.GetApplicationList();
    },
    OpenModel: function() {
      this.classicModal = true;
      this.CreateWebToken();
      this.CreateAppModalHide();
    },
    AddManualApp() {
      if (!this.create_application_package_name) {
          this.$toast.error("Package name required");
          return false;
      }
      let expr = /^[a-zA-Z0-9._]*$/;
      if (!expr.test(this.create_application_package_name)) {
          this.$toast.error("Only Alphabets, Numbers, Dot and Underscore allowed in Package Name.");
          return false;
      }
      this.isDisabled = true;
      var data = {
          product_id: "app:"+this.create_application_package_name,
          package_name: this.create_application_package_name,
          is_manual_app: true,
      };
      CreateApplication(data);
      setTimeout(()=> {
        this.isDisabled = false;
        this.CreateAppModalHide();
        this.GetApplicationList();
      }, 2500)
    },

    AddWebApp() {
      this.isDisabled = true;
      let data = {
        //name: this.web_app_name,
        title: this.web_app_title,
        startUrl: this.web_app_start_url,
        displayMode: this.web_app_display_mode,
        versionCode: this.web_app_version_code
      }
      ApplicationDataService.AddWebApp(data)
        .then((response) => {
          this.isDisabled = false;
          this.CreateWebAppModel = false;
          this.GetApplicationList();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    
    SyncWebApp() {
      this.isDisabled = true;
       ApplicationDataService.SyncWebApp()
        .then((response) => {
          this.isDisabled = false;
          this.GetApplicationList();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    GetApplicationList() {
      ApplicationDataService.GetApplicationList("","Android")
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    CreateWebToken() {
      var data ={};
      ApplicationDataService.CreateWebToken(data)
        .then((response) => {
          if (response.data.data) {
            this.token = response.data.data;
            LoadIframe(this.token);
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    DeleteManualApp(package_name) {
      let message = `This application also be deleted from profile application list`;
      let data = {
          package_name: package_name,
          type: "Android"
      };
      ApplicationDataService.ListLinkProfiles(data)
      .then((response) => {
          if (response.data.data.length > 0) {
              message += "<ul>";
              for (let content of response.data.data) {
                  message += '<li>'+content.profile_id.profile_name+'</li>';
              }
              message += "</ul>";
          }
          Swal.fire({
            title: "Are you sure?",
            //text: `You won't be able to revert this!`,
            html:message,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              var data = { "package_name" : package_name, "type": "Android" };
              ApplicationDataService.DeleteApplication(data)
                .then((response) => {
                  this.$toast.success(response.data.respones_msg);
                  this.GetApplicationList();
                })
                .catch((e) => {
                  if (e.response) {
                    this.$toast.error(e.response.data.respones_msg);
                  }
                });
            }
          });
      });
      
    },  

    SearchPlayStoreApp() {
        if (!this.search_application) {
          this.$toast.error("Please enter application name to search");
        }
        ApplicationDataService.SearchPlayStoreApp(this.search_application, this.country)
        .then((response) => {
          this.play_store_applications = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });    
    },

    AddToProfileApplication(app) {
        let data = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android"
        };
        ApplicationDataService.CreateIosApplication(data)
        .then((response) => {
            let updated_app_store_application = this.play_store_applications.filter(elem => elem.appId != app.appId)
            this.profile_applications.push(app);
            this.play_store_applications = updated_app_store_application;
            this.GetApplicationList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetApplicationList();
  },
};
function LoadIframe(token){
  gapi.load('gapi.iframes', function() {
    var options = {
      'url': 'https://play.google.com/work/embedded/search?token='+token+'&mode=APPROVE',
      'where': document.getElementById('container'),
      'attributes': { style: 'width: 900px; height:400px', scrolling: 'no'}
    }

    var iframe = gapi.iframes.getContext().openChild(options);
    iframe.register('onproductselect', function(event) {
      if (event.action == "approved") {
        var data = {
          product_id: event.productId,
          package_name: event.packageName,
          is_manual_app: false,
        };
        CreateApplication(data);
      }
      if (event.action == "unapproved") {
        var data = {
          product_id: event.productId,
          package_name: event.packageName,
        };
        DeleteApplication(data);
      }
    }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);
  });
}

function CreateApplication(data) {  
  ApplicationDataService.CreateApplication(data)
    .then((response) => {
      return true;
    })
    .catch((e) => {
      alert(e.response.data.respones_msg);
    });
}

function DeleteApplication(data) {  
  ApplicationDataService.DeleteApplication(data)
    .then((response) => {
    })
    .catch((e) => {
      this.$toast.error(e);
    });
}

</script>
<style scoped>
.search_text_box {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    float: left;
    width: 80%;
}
.scrollit {
	overflow:scroll;
	height:250px;
}
</style>

<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <md-select class="box-textbox" placeholder="Invoice Term" v-model="invoiceTerm">
                      <md-option value="">Select Invoice Term</md-option>
                      <md-option value="Yearly">Yearly</md-option>
                      <md-option value="Periodically">Periodically</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-button class="md-primary" :disabled="isDisabled"  @click="invoicesList()">Search</md-button> 
              </div>
          </div>
        <p v-if="tableData.length == 0">No data found.</p>
          <md-table v-if="tableData.length > 0" v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="Email">
                {{ item.resellerEmail }} <br>
                <small> <b>Device Price: {{ item.devicePrice }} </b></small>
                <small v-if="item.discountValue > 0"><br> <b>Discount : {{ item.discountValue }} {{ item.discountType }} </b><br></small>
                <small v-if="item.discountValue > 0"><b> Device Price After Discount : {{ item.devicePriceAfterDiscount }}</b><br></small>
              </md-table-cell>
              <md-table-cell md-label="Bill Amount">
                  <b>Amount : {{ totalInvoiceAmount(item.invoiceAmount, item.deletedDeviceAmount) }}</b>
                  <small v-if="item.discountAmount > 0"><br> <b>Discount : {{ item.discountAmount }}</b></small>
                  <!--<br> <b>Purchased Licenses: {{ totalDevices(item.totalDevices, item.totalDeletedDevices) }} Devices</b>-->
                  <br> <small><strong>Purchased Licenses: {{ item.purchasedLicense }} </strong></small>
                  <br> <small class="text-danger"><strong>Deleted Devices: {{ item.totalDeletedDevices }} </strong></small>
               </md-table-cell>
              <md-table-cell md-label="Validity">
                {{ item.validity }}
                <br> <a href="javascript:void(0)" @click="$router.push({ path: 'admin-invoice-customers', query: { id: item._id }})">{{ item.invoiceDuration }}</a>
              </md-table-cell>
              <md-table-cell md-label="Invoice Term">
                 <p class="badge-blue" v-if="item.invoiceTerm == 'Yearly'">Yearly</p>
                  <p class="badge-red" v-else>Periodically</p>
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
              <md-table-cell md-label="Action">
                <div style="display: block ruby;">
                  <md-button class="md-just-icon md-primary m-0 md-simple" @click="$router.push({ path: 'admin-invoice-customers', query: { id: item._id }})"><md-icon>visibility</md-icon></md-button>
                  <a :href="baseUrl+'/upload/invoice/'+item._id+'.pdf'" target="_blank" class="md-just-icon md-success m-0 md-simple"><md-icon>picture_as_pdf</md-icon></a>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import SuperUserService from "../../../services/SuperAdmin/SuperUserService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      invoiceTerm:"",
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
      invoicesList() {
        let data = { resellerId: this.$route.query.id, invoiceTerm: this.invoiceTerm };
        SuperUserService.invoicesList(data)
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      totalDevices(activeDevices, deletedDevices) {
        return activeDevices + deletedDevices;
      },
      totalInvoiceAmount(invoiceAmount, deletedDeviceAmount) {
        return invoiceAmount + deletedDeviceAmount;
      }
  },
  beforeMount() {
    this.invoicesList();
  },
};
</script>

<style>
</style>

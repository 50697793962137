import http from "../../http-common-super-admin";

class SuperUserService {
  superUserList(data) {
    return http.get("/users/superUser/list", { params: data });
  }
  superUserCreate(data) {
    return http.post("/users/superUser/create", data);
  }
  superUserDelete(data) {
    return http.post("/users/superUser/delete", data);
  }
  superUserDetail(data) {
    return http.get("/users/superUserDetail", { params: data });
  }
  superUserUpdate(data) {
    return http.post("/users/superUser/update", data);
  }

  invoicesList(data) {
    return http.get("/invoices/list", { params: data });
  }
  invoiceCustomerList(data) {
    return http.get("/invoices/customer/list", { params: data });
  }
  invoiceCustomerDevicesList(data) {
    return http.get("/invoices/customer/devices/list", { params: data });
  }
}

export default new SuperUserService();
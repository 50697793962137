<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
          <md-button class="md-success mr-2" :disabled="isDisabled" @click="applyPolicy()">
            Apply Policy<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
          <md-button class="md-danger mr-2" @click="$router.push('device-profiles')">Back</md-button>
      </div>
      <tabs
        :tab-name="[
          'Play Store',
          'Chrome Store',
          'Kiosk',
          'Devices'
        ]"
        color-button="warning"
      >
        
        <template slot="tab-pane-1">
          <md-card>
            <md-card-content>
              <div class="md-layout" v-if="applications">
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <md-input type="text" placeholder="Search Applications" v-model="search_package_name"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                  <md-button class="md-primary" :disabled="isDisabled" @click="GetApplicationList()">Search</md-button> 
                  <!--&nbsp;<md-button class="md-success" @click="$router.push({ path: 'applications'})">More Apps</md-button>-->
                </div>
              </div>
              <div class="md-layout">
                <h4 v-if="applications.length == 0">No Application found</h4>
                <div class="app-listing-copy" v-if="applications">
                  <ul id="product-directory">
                    <li v-for="app in applications" :key="app._id">
                        <img :src=app.app_icon style="">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.package_name,app.is_web_app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name" v-if="!app.application_details"><small><b>{{app.package_name }}</b></small></span>
                          <span class="apptext" :title="app.package_name" v-else><small><b>{{app.application_details.title }}</b>  ({{ app.package_name }})</small></span>
                        </div>
                    </li>
                    <!-- Play Store Application Append -->
                    <li v-for="app in storeApps" :key="app.appId">
                        <img :src=app.icon style="opacity: 0.5;">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.appId, false, app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name"><small><b>{{app.title }}</b>  ({{ app.appId }})</small></span>
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
              <md-table
                v-model="profileApplications"
                table-header-color="green"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Package Name">
                    <div style="display:flex;">
                      <img :src="item.app_icon" style="height: 50px;width: 50px;" />&nbsp;
                    <p>{{ item.display_text }} <span v-if="item.has_title"> ({{ item.package_name }})</span></p>
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="App Status">
                    <select class="form-control" @change="updateProfileApplication(item.package_name,$event)">
                      <option value="AVAILABLE" v-bind:selected="item.install_type == 'AVAILABLE'">AVAILABLE</option>
                      <option value="FORCED" v-bind:selected="item.install_type == 'FORCED'">FORCE INSTALLED</option>
                      <option value="BLOCKED" v-bind:selected="item.install_type == 'BLOCKED'">BLOCK & UNINSTALL</option>
                    </select>  
                  </md-table-cell>
                  <md-table-cell md-label="Actions">
                    <div style="display: flex;">                    
                      <md-button class="md-just-icon md-danger md-simple" @click="DeleteProfileApplication(item.package_name)"><md-icon>close</md-icon></md-button>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </template>
        
        <template slot="tab-pane-2">
          <md-card>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <md-input type="text" placeholder="Search Applications" v-model="search_chrome_package_name"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                  <md-button class="md-primary" :disabled="isDisabled" @click="getChromeApplicationList()">Search</md-button> 
                </div>
              </div>
              <div class="md-layout">
                <h4 v-if="chromeApplications.length == 0 && webStoreApps.length == 0">No Application found</h4>
                <div class="app-listing-copy">
                  <ul id="product-directory">
                    <li v-for="app in chromeApplications" :key="app._id">
                        <img :src=app.app_icon style="">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.package_name,false)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.application_details.name" ><small><b>{{app.application_details.name }}</b></small></span>
                        </div>
                    </li>
                    <!-- Play Store Application Append -->
                    <li v-for="app in webStoreApps" :key="app.id">
                        <img :src="app.images['128x128']" style="opacity: 0.5;">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.id, false, app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.name"><small><b>{{app.name }}</b> </small></span>
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
              <md-table
                v-model="profileChroneApplication"
                table-header-color="green"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Package Name">
                    <div style="display:flex;">
                      <img :src="item.app_icon" style="height: 50px;width: 50px;" />&nbsp;
                    <p>{{ item.display_text }} </p>
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="App Status">
                    <select class="form-control" @change="updateProfileApplication(item.package_name,$event)">
                      <option value="AVAILABLE" v-bind:selected="item.install_type == 'AVAILABLE'">AVAILABLE</option>
                      <option value="FORCED" v-bind:selected="item.install_type == 'FORCED'">FORCE INSTALLED</option>
                      <option value="BLOCKED" v-bind:selected="item.install_type == 'BLOCKED'">BLOCK & UNINSTALL</option>
                    </select>  
                  </md-table-cell>
                  <md-table-cell md-label="Actions">
                    <div style="display: flex;">                    
                      <md-button class="md-just-icon md-danger md-simple" @click="DeleteProfileApplication(item.package_name)"><md-icon>close</md-icon></md-button>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </template>

        <template slot="tab-pane-3">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="updateKioskStatus()">
                  Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
              </div>
              <br /><br />
              <div class="md-layout-item md-size-100">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="None">None</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="singleApp" key="101">Single App</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="Web" key="101">Web</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="MULTI APP" key="102">Multi App</md-radio>
                  </div>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 kiosk-div">
                    <!-- Single App Kiosk-->  
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'singleApp'">
                      <div v-if="profileChroneApplication.length > 0">
                        <h4><b>Select one application to lockdown your devices.</b></h4>
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileChroneApplication" :key="app._id">
                            <input type="radio" class="radio-d-none" name="package_name" v-model="package_name" :value="app.package_name" :id="app._id" />
                            <label class="label" :for="app._id"><img :src="app.app_icon" class="app-icon-single" /></label>
                            <span class="apptext">
                              <small><b>{{ app.display_text }}</b></small>
                            </span> 
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Web Kiosk-->  
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'Web'">
                      <div class="md-layout">
                        <div class="md-layout-item">
                          <md-field>
                            <label><b>Web Url <span style="color:red;">*</span></b></label>
                            <md-input type="url" v-model="webKioskUrl"></md-input>
                          </md-field>
                        </div>
                      </div>
                    </div>

                    <!-- Multi App Kiosk-->  
                    <div class="md-layout-item md-size-100" v-if="kiosk_type == 'MULTI APP'">
                      <div v-if="profileChroneApplication.length > 0">
                        <h4><b>Select any application to lockdown your devices.</b></h4>
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileChroneApplication" :key="app.package_name">
                            <input type="checkbox" class="radio-d-none" v-model="multiAppKioskPackages" :value="app.package_name" :id="app.package_name" />
                            <label class="label" :for="app.package_name"><img :src="app.app_icon" class="app-icon-single" /></label>
                            <span class="apptext">
                              <small><b>{{ app.display_text }}</b></small>
                            </span> 
                          </li>
                        </ul>
                      </div>
                      <div v-for="(textField, i) in textFields" :key="i">
                          <div class="md-layout">
                              <div class="md-layout-item md-size-80">
                                  <md-field>
                                      <label>{{ textField.label1 }}</label>
                                      <md-input name="webUrls[]" v-model="textField.value1" type="text"></md-input>
                                  </md-field>
                              </div>
                              <div class="md-layout-item md-size-10" :style="{ 'padding-top': '10px' }">
                                  <md-button class="md-just-icon md-danger md-simple" @click="deleteTextBox(i)">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                  </md-button>
                              </div>
                          </div>
                      </div>
                      <md-button class="md-primary" @click="addTextBox">Add Web Url</md-button>
                    </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </template>

        <template slot="tab-pane-4">
          <md-card>
              <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <p v-if="profileDevices.length == 0">No device found for this profile</p>
                      <md-table v-model="profileDevices" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Serial Number">
                            <a @click="$router.push({ path: 'chrome-device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.serialNumber }}</a>
                          </md-table-cell>
                          <md-table-cell md-label="Enrollment Time">{{
                            HumanReadableDateFormat(item.enrollmentTime)
                          }}</md-table-cell>
                          <md-table-cell md-label="Last Policy SyncTime">{{
                            HumanReadableDateFormat(item.lastPolicySyncTime)
                          }}</md-table-cell>
                          <md-table-cell md-label="Model" v-if="item.hardwareInfo">{{
                            item.hardwareInfo.model
                          }}</md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                    </div>
            </md-card-content>
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from "@/components";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import ApplicationDataService from "../../services/ApplicationDataService";
import ChromeDataService from "../../services/ChromeDataService";

export default {
  components: {
    Tabs,
  },
  data() {
    return {
      profile_name: null,
      profile_id: this.$route.query.profile_id,
      isDisabled: false,
      applications: [],
      profileApplications: null,
      profileApplicationsKiosk: [],
      
      // Application
      permissionModal: false,
      applicationPermissionList: [],
      permission_package_name: null,
      search_package_name: "",
      storeApps: [],

      //Chrome Application
      chromeApplications: [],
      profileChroneApplication: [],
      search_chrome_package_name:"",
      webStoreApps: [],
      searchChromeApps: [],

      //Kiosk
      kiosk_type: "None",
      package_name:"",
      webKioskUrl:"",

      //Multi App Kiosk
      multiAppKioskPackages: [],
      textFields: [
            { 
              label1: "Web Url 1", 
              value1: "",
            }
        ],

      //Devices
      profileDevices: [],
    };
  },
  methods: {
    GetDeviceProfileDetail() {
      DeviceProceDataService.GetDeviceProfileDetail(
        this.$route.query.profile_id
      )
        .then((response) => {
          if (response.data.data) {
            this.profile_name = response.data.data.profile_name;
            this.kiosk_type = response.data.data.kiosk_type;
            this.package_name = response.data.data.single_app_kiosk_package_name;
            this.webKioskUrl = response.data.data.webKioskUrl;
            this.multiAppKioskPackages = response.data.data.multiAppKioskPackages;
            if (response.data.data.webUrlsList.length > 0) {
                this.textFields = [];
                response.data.data.webUrlsList.forEach(url => {
                    this.textFields.push({ 
                        label1: "Web Url", 
                        value1: url,
                    });
                })
            }
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    GetApplicationList() {
      this.applications = [];
      ApplicationDataService.GetApplicationList(this.profile_id,"Android","",false,this.search_package_name)
        .then((response) => {
          if (response.data.data) {
            this.applications = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });

        if (this.search_package_name) {
           ApplicationDataService.SearchPlayStoreApp(this.search_package_name, "us")
            .then((response) => {
              this.storeApps = response.data.data;
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });    
        } else {
          this.storeApps = [];
        }
    },

    GetProfileApplicationList() {
      this.profileApplications = [];
      DeviceProceDataService.GetProfileApplicationList(
        this.$route.query.profile_id, "Android"
      )
        .then((response) => {
          if (response.data.data) {
            this.profileApplications = response.data.data;
            this.profileApplicationsKiosk = response.data.data.filter(i => i.is_app_disabled == false);
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },


    async CreateProfileApplication(package_name, is_web_app, app = "") {
      if (app) {
        let data = {
            product_id: app.id,
            package_name: app.id,
            app_icon: app.images['128x128'],
            application_details: app,
            type: "Chromebook"
        };
        await ApplicationDataService.CreateIosApplication(data)
        .then((response) => {
            let updated_app_store_application = this.webStoreApps.filter(elem => elem.id != app.id)
            this.webStoreApps = updated_app_store_application;
            this.search_chrome_package_name = "";
        }).catch((e) => {
          let updated_app_store_application = this.storeApps.filter(elem => elem.id != app.id)
          this.webStoreApps = updated_app_store_application;
          this.search_chrome_package_name = "";
        })
      }

      var data = {
        profile_id: this.$route.query.profile_id,
        package_name: package_name,
        is_web_app: is_web_app,
      };

      DeviceProceDataService.CreateProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.GetProfileApplicationList();
          this.GetApplicationList();
          this.getProfileChromeApplicationList();
          this.getChromeApplicationList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    DeleteProfileApplication(package_name) {
      var data = {
        package_name: package_name,
        profile_id: this.$route.query.profile_id,
      };

      DeviceProceDataService.DeleteProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.GetProfileApplicationList();
          this.GetApplicationList();
          this.getProfileChromeApplicationList();
          this.getChromeApplicationList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    updateProfileApplication(packageName, event) {
      let data = {
        packageName: packageName,
        installType: event.target.value,
        profileId: this.profile_id
      }
      ChromeDataService.updateProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    updateKioskStatus() {
      let webUrlsList = $("input[name='webUrls[]']")
      .map(function(i){
            if($(this).val()) {
                return $(this).val();
            }
        }
        ).get();
      let data = {
        kiosk_type: this.kiosk_type,
        single_app_kiosk_package_name: this.package_name,
        profile_id: this.profile_id,
        webKioskUrl: this.webKioskUrl,
        multiAppKioskPackages: this.multiAppKioskPackages,
        webUrlsList: webUrlsList,
      }
      DeviceProceDataService.UpdateProfileKiosk(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    applyPolicy() {
        this.isDisabled = true;
      let data = {
        profileId: this.profile_id
      }
      ChromeDataService.applyPolicy(data)
        .then((response) => {
            this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    getChromeApplicationList() {
      this.chromeApplications = [];
      ApplicationDataService.GetApplicationList(this.profile_id,"Chromebook","",false,this.search_chrome_package_name)
        .then((response) => {
          this.chromeApplications = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });

      if (this.search_chrome_package_name) {
            let data = {
              searchKeyword : this.search_chrome_package_name,
              category: "extensions"
          }
           ApplicationDataService.searchChromeStoreApp(data)
            .then((response) => {
              this.webStoreApps = response.data.data;
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });    
        } else {
          this.webStoreApps = [];
        }
    },

    getProfileChromeApplicationList() {
      this.profileChroneApplication = [];
      DeviceProceDataService.GetProfileApplicationList(
        this.$route.query.profile_id, "Chromebook"
      )
        .then((response) => {
          this.profileChroneApplication = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    KioskTypeChange() {
      if (this.profileApplications.length == 1) {
        if (this.kiosk_type == "singleApp") {   
            this.package_name = this.profileApplications[0].package_name;
        } else {
            this.package_name = "";
        }
      }
    },

    GetProfileDeviceList() {
      this.profileDevices = [];
      let data = {
        profile_id: this.$route.query.profile_id,
        serial_number: "",
        user_id: "",
        hide_assign_devices: false,
        type: "",
        imei_number: "",
        model: "",
      }
      DeviceProceDataService.GetProfileDeviceList(data)
        .then((response) => {
          this.profileDevices = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    addTextBox () {
          this.textFields.push({ 
          label1: "Web Url " + (this.textFields.length + 1) , 
          value1: "",
          })
      },
      
      deleteTextBox (index) {
          this.textFields.splice(index, 1)
      },

  },
  beforeMount() {
    this.GetDeviceProfileDetail();
    this.GetApplicationList();
    this.GetProfileApplicationList();
  },
  mounted: function () {
    this.$nextTick(() => {
      const that = this;
      $("#1").on("click", function() {
        that.getChromeApplicationList();
        that.getProfileChromeApplicationList();
      });
      $("#2").on("click", function() {
        that.getChromeApplicationList();
        that.getProfileChromeApplicationList();
      });
      $("#3").on("click", function() {
        that.GetProfileDeviceList();
      });
    })
  }
};
</script>
<style>
/*.md-card-header {
  border-bottom: 1px solid black;
  padding: 5px !important; 
}*/
.tab-content {
  display: unset !important;
}
input[type="text"]:disabled {
    background-color: #dddddd;
}
.modal {
  min-height:400px;
  height:400px;
  overflow:scroll;
}

.loader-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: unset !important;
  width: unset !important;
}
.md-table-head {
  background: #ebedee;
}
.autoconnect .md-checkbox .md-checkbox-label{
  position: unset !important;
}

.custom-checkbox .md-checkbox-label {
  color: unset !important;
}

.custom-radio .md-radio-label {
  color: unset !important;
  font-weight: bold;
}

.screencellbook {
    width: 100px;
    height: 125px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    margin: 5px;
    padding: 3px;
}

.screencellbook>img {
    display: inline-block;
    max-width: 100%;
    max-height: 65%;
}

.badge-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #f1c7c5;
    border-radius: 5px;
    font-size: 14px;
}

.screencellbook .apptext {
    /*float: left;
    width: 100%;
    margin-top: 0px;*/
    color: rgb(0, 0, 0);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 0;
    word-break: break-all;
}
.md-menu-content{
  max-width: 45% !important;
}
.text-fields-row {
  display: flex;
}
.md-dialog-actions {
  position: unset !important;
}
.md-card-tabs .md-list{
  flex-flow: wrap !important;
}
.mt-2{
  margin-top: 2px !important;
}
.f-right-padding {
    width: 12%;
    text-align: right;
    float: right;
    margin-right: 2px;
}

.ul-class {
    list-style-type: none;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
}

.li-class {
  display: inline-block;
  text-align: center;
}

.radio-d-none {
  display: none;
}

.app-icon-single {
    height: 75px !important;
    width: 75px !important;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}
.d-block {
  display: block;
}
.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.label img {
  height: 75px;
  width: 75px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + .label {
  border-color: #ddd;
}

:checked + .label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:not(:checked) + .label:before {
  content: "";
  background-color: #ffffff;
  transform: scale(0.5);
}

:checked + .label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}


.w-50 {
  width: 50%;
}

#product-directory {
  padding: unset;
  box-sizing: border-box;
}
#product-directory ul {
  padding-left: 0;
  box-sizing: border-box;
}
#product-directory li {
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  margin: 5px;
  position: relative;            
  border: 1px solid #d3d3d3;
  align-content: center;
  display: inline-block; 
  vertical-align: top;
}
#product-directory li img {
  max-width: 65%;
  height: 85px;
  margin: 0 auto;
  position: relative;
  align-self: center;
  margin:0 auto;
  display:block;
}
#product-directory li span {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  /*background-color: #6a6969;*/
  padding: 5px;
  color: black;
  min-height: 34px;
  word-break: break-all;
  height: 75px;
}

</style>
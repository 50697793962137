<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
          <md-card-content>
              <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                      <md-field>
                          <select v-model="category" class="browser-default custom-select" style="padding: 9px;border: none;">
                            <option value="extensions">Extensions</option>
                            <option value="themes">Themes</option>
                        </select>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                      <md-field>
                          <md-input type="text" v-model="searchKeyword" placeholder="Search Applications" ></md-input>
                          <button class="search_btn" :disabled="isDisabled" @click="searchChromeStoreApp()"><i class="fa fa-search"></i></button>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                      <div v-if="webStoreApplications.length > 0" class="scrollit">
                          <div class="app-listing box-shadow" v-if="webStoreApplications">
                              <div class="screencellbook" v-for="app in webStoreApplications" :key="app.appId">
                              <img :src="app.images['128x128']">
                              <a class="badge-icon" href="javascript:void(0);" @click="AddToProfileApplication(app)">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                              </a>
                              <span class="apptext" :title="app.name">{{ app.name }}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </md-card-content>
      </md-card>
      <md-card>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Package Name" md-sort-by="package_name">
                <img :src="item.app_icon" style="height: 50px;width: 50px;" /> 
                <span> {{ item.application_details.name }} </span>
              </md-table-cell>
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
        
      </md-card>
    </div>
  </div>
</template>
<script>
import ApplicationDataService from "../../services/ApplicationDataService";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],

      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",

      // Search Section
      searchKeyword: "",
      category: "extensions",
      webStoreApplications: [],

    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    GetApplicationList() {
      ApplicationDataService.GetApplicationList("","Chromebook")
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    searchChromeStoreApp() {
        if (!this.searchKeyword) {
          this.$toast.error("Please enter application name to search");
          return;
        }
        let data = {
            searchKeyword : this.searchKeyword,
            category: this.category
        } 
        ApplicationDataService.searchChromeStoreApp(data)
        .then((response) => {
          this.webStoreApplications = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });    
    },

    AddToProfileApplication(app) {
        let data = {
            product_id: app.id,
            package_name: app.id,
            app_icon: app.images['128x128'],
            application_details: app,
            type: "Chromebook"
        };
        ApplicationDataService.CreateIosApplication(data)
        .then((response) => {
            let updatedWebStoreApplication = this.webStoreApplications.filter(elem => elem.id != app.id)
            this.webStoreApplications = updatedWebStoreApplication;
            this.GetApplicationList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetApplicationList();
  },
};

</script>
<style scoped>
.search_text_box {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    float: left;
    width: 80%;
}
.scrollit {
	overflow:scroll;
	height:250px;
}
</style>
